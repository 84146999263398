import React, { PropsWithChildren } from "react";
import { RepleekProvider } from "@repleek/mui";
import pages from "@/pages";
import themes from "@/theme";
import i18n from "@/locales/i18n";
import { Route, Routes, useNavigate } from "react-router-dom";
import { StrapiProvider } from "./providers/StrapiProvider";
import { RecoilRoot } from "recoil";
import { ToastContainer } from "react-toastify";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import "./styles/global.css";
import "react-toastify/dist/ReactToastify.css";
import { useThemeChange } from "./hooks/layout";

const App: React.FC = () => {
  const push = useNavigate();

  return (
    <RecoilRoot>
      <StrapiProvider>
        <RepleekProvider
          push={push}
          themes={themes}
          i18n={i18n}
          dateAdapter={AdapterDayjs}>
          <Provider>
            <Routes>
              {pages.map(({ path, page }) => (
                <Route element={page} path={path} key={path} />
              ))}
            </Routes>
          </Provider>
          <ToastContainer />
        </RepleekProvider>
      </StrapiProvider>
    </RecoilRoot>
  );
};

const Provider: React.FC<PropsWithChildren> = ({ children }) => {
  useThemeChange();
  return <>{children}</>;
};

export default App;
