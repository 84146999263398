import React, { useCallback, useMemo, useState } from "react";
import {
  AccountList,
  AccountPagination,
  accountType,
} from "@/components/features/account/AccountList";
import { AccountFilterForm, ListAccount } from "@/components";
import { useRecoilState, useResetRecoilState } from "recoil";
import { accountQuery } from "@/store/accounts";
import { Modal } from "@/components/layout";
import { Button, Chip } from "@mui/material";
import { Icon } from "@repleek/mui";
import { CiFilter } from "react-icons/ci";

const AdminHome: React.FC = () => {
  const [count, setCount] = useState(0);
  const [filterModal, setFilterModal] = useState(false);
  const [query, setAccountQuery] = useRecoilState(accountQuery);
  const resetQuery = useResetRecoilState(accountQuery);
  const options = useMemo(
    () => [
      { label: "Classique", value: "classic" },
      { label: "Réstaurant", value: "restaurant" },
    ],
    []
  );
  const defaultValues = useMemo(
    () => ({
      type: (query.type || []).map((v) =>
        options.find(({ value }) => value === v)
      ),
    }),
    [options, query.type]
  );

  const onSearch = useCallback(
    (value: { search: string }) => {
      setAccountQuery(({ pagination, ...query }) => ({
        ...query,
        pagination: {
          ...pagination,
          page: 1,
        },
        search: value.search,
      }));
    },
    [setAccountQuery]
  );

  const applyFilter = useCallback(
    ({
      type,
    }: {
      type: { value: "classic" | "restaurant"; label: string }[];
    }) => {
      setAccountQuery(({ pagination, ...query }) => ({
        ...query,
        pagination: {
          ...pagination,
          page: 1,
        },
        type: type.map(({ value }) => value),
      }));
      setFilterModal(false);
    },
    [setAccountQuery]
  );

  return (
    <>
      <ListAccount
        accounts={{
          children: <AccountList setCount={setCount} />,
        }}
        pagination={{
          children: <AccountPagination count={count} />,
        }}
        addBtn={{ style: { display: !count ? "none" : "inline" } }}
        search={{
          onSubmit: onSearch,
        }}
        filterBtn={{
          onClick: () => setFilterModal(true),
        }}
        filter={{
          children: (
            <>
              {query.type?.map((v) => (
                <Chip
                  icon={<Icon icon={accountType[v].icon} fontSize="small" />}
                  label={accountType[v].label}
                  variant="filled"
                  color="primary"
                  sx={{
                    mr: 1,
                    px: 1,
                    textTransform: "uppercase",
                  }}
                  key={v}
                />
              ))}
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={resetQuery}
                sx={{
                  py: 0.5,
                  px: 2,
                }}>
                Effacer
              </Button>
            </>
          ),
          hidden: !query.type?.length,
        }}
        css={{
          paddingTop: "16px",
          paddingBottom: "16px",
        }}
      />

      <Modal
        type="form"
        open={filterModal}
        title={"Filtrer les comptes"}
        icon={{ icon: CiFilter }}
        onConfirm={() => {}}
        onClose={() => setFilterModal(false)}
        form={{ defaultValues, onSubmit: applyFilter }}>
        <AccountFilterForm
          type={{
            inputName: "type",
            options,
          }}
        />
      </Modal>
    </>
  );
};

export default AdminHome;
