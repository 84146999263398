import { strapiInstance } from "@/providers/StrapiProvider";
import { selectorFamily } from "recoil";

type Params = {
  id: string;
  range: {
    start: string;
    end: string;
  };
};

export const postSelector = selectorFamily({
  key: "post-by-range",
  get:
    ({ id, range }: Params) =>
    async () => {
      const res = await strapiInstance.find("posts", {
        filters: {
          account: {
            id: { $eq: id },
          },
          scheduledSendTime: {
            $between: [range.start, range.end],
          },
        },
        populate: ["socialProfileIs", "video", "media"],
        pagination: {
          page: 1,
          pageSize: 100,
          withCount: true,
        },
      });

      return res?.data;
    },
});
