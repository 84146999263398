import React, { Suspense } from "react";
import Layout, { PistacheLoader } from "@/components/layout";

import { useRecoilValue } from "recoil";
import { authState } from "@/store/auth";
import UserHome from "@/components/features/user/UserHome";
import AdminHome from "./AdminHome";

const HomePage: React.FC = () => {
  const user = useRecoilValue(authState);

  return (
    <Layout mode="LOGGED_IN">
      {user && ["cm", "admin"].includes(user.type) ? (
        <Suspense fallback={<PistacheLoader />}>
          <AdminHome />
        </Suspense>
      ) : (
        <Suspense fallback={<PistacheLoader />}>
          <UserHome />
        </Suspense>
      )}
    </Layout>
  );
};

export default HomePage;
