import { ButtonBase, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import folderImg from "./folder.svg";

type Props = Pick<DropzoneOptions, "accept"> & { disabled?: boolean } & (
    | {
        multiple: false;
        onChange: (file: File) => void;
      }
    | {
        multiple: true;
        onChange: (file: File[]) => void;
      }
  );

const FilePicker: React.FC<Props> = (props) => {
  const { accept, disabled, multiple, onChange } = props;

  const extensions = useMemo(
    () => (accept ? Object.values(accept).flatMap((ext) => ext) : []),
    [accept]
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      // Do something with the files
      if (!multiple) {
        return onChange(acceptedFiles[0]);
      }
      return onChange(acceptedFiles);
    },
    [multiple, onChange]
  );

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      accept,
      multiple,
      disabled,
    });

  useEffect(() => {
    if (fileRejections.length) {
      fileRejections.forEach(({ errors }) => {
        errors.forEach(({ code }) => {
          let message =
            "Impossible de charger le fichier. Veuillez vérifier que le fichier n'est pas corrompu ou essayez de le recharger.";
          switch (code) {
            case "file-invalid-type":
              message =
                extensions.length > 1
                  ? `Les types de fichiers acceptés sont : ${extensions.join(
                      ", "
                    )}.`
                  : `Le type de fichier accepté est : ${extensions[0]}.`;
              break;
          }
          toast(message, { type: "error" });
        });
      });
    }
  }, [extensions, fileRejections]);

  return (
    <ButtonBase
      {...getRootProps()}
      disabled={disabled}
      sx={{
        width: "100%",
        minWidth: "348px",
        padding: "16px",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        border: "2px dashed #53FE7A",
        borderRadius: "16px",
        my: 1,
      }}
      focusRipple>
      <input {...getInputProps()} style={{ display: "none" }} />
      <img
        src={folderImg}
        alt=""
        style={{ width: "130px", display: "block", padding: "16px" }}
      />
      <Typography minHeight={48}>
        {isDragActive
          ? "Déposez les fichiers ici..."
          : "Faites glisser et déposez des fichiers ici, ou cliquez pour sélectionner des fichiers"}
      </Typography>
    </ButtonBase>
  );
};

export default FilePicker;
