import React, { Suspense, useCallback } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import Notification from "./Notification";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  notificationModal,
  notificationQuery,
  notificationsSelector,
} from "@/store/notification";
import { PistacheLoader } from "../modals";
import { authState } from "@/store/auth";
import { useNavigate } from "@repleek/mui";
import { ContentType } from "@/modules/strapi-sdk/lib/api";
import { useStrapi } from "@/providers/StrapiProvider";

const Notifications: React.FC = () => {
  const [query, setQuery] = useRecoilState(notificationQuery);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setQuery((query) => ({ ...query, archived: !!newValue }));
  };

  return (
    <Box sx={{ width: "380px" }}>
      <Box
        sx={{
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
          position: "sticky",
          top: 0,
          background: "white",
          zIndex: 1,
        }}>
        <Typography
          variant="h3"
          sx={{
            fontSize: "24px",
            fontWeight: 900,
            letterSpacing: "2px",
            py: 1.6,
            textAlign: "center",
          }}>
          Notifications
        </Typography>

        <Tabs value={query.archived ? 1 : 0} onChange={handleChange}>
          <Tab label="Toutes" sx={{ fontWeight: 500, letterSpacing: "2px" }} />
          <Tab
            label="Archivées"
            sx={{ fontWeight: 500, letterSpacing: "2px" }}
          />
        </Tabs>
      </Box>

      <Suspense fallback={<PistacheLoader />}>
        <List />
      </Suspense>
    </Box>
  );
};

const List: React.FC<{}> = (props) => {
  const notifications = useRecoilValue(notificationsSelector);
  const user = useRecoilValue(authState);
  const [strapi] = useStrapi();

  const { navigate } = useNavigate();
  const setNotification = useSetRecoilState(notificationModal);

  const onOpen = useCallback(
    (notifocation: ContentType["notifications"]) => {
      if (notifocation.id && user && notifocation?.read_by?.includes(user.id))
        strapi?.update("notifications", notifocation.id, {
          read_by: [user.id],
        });

      notifocation.action && navigate?.(notifocation.action?.path);
      setNotification(false);
    },
    [navigate, setNotification, strapi, user]
  );

  return (
    <>
      {notifications?.data?.map((notification) => (
        <Notification
          notifocation={notification}
          key={notification.id}
          onClick={onOpen}
          userId={user?.id}
        />
      ))}
    </>
  );
};

export default Notifications;
