import React from "react";
import { ButtonBase } from "@mui/material";
import { socialIcons } from "@/components/features/account/SocialDetail";

export type Tab = "linkedin" | "tiktok" | "facebook" | "instagram";

const Tabs: React.FC<{
  socials: string[];
  tab: Tab;
  onChange: (tab: Tab) => void;
}> = (props) => {
  const { socials, onChange, tab } = props;

  return (
    <>
      {Object.entries(socialIcons).map(
        ([
          type,
          {
            icon: { icon: Icon, style },
            title,
          },
        ]) =>
          Icon &&
          socials.includes(type) && (
            <ButtonBase
              key={title}
              className={tab === type ? "active" : ""}
              sx={{
                width: "48px",
                height: "48px",
                borderRadius: "16px",
                background: "#ffffff12",
                display: "inline-flex",
                flexDirection: "column",
                gap: "8px",
              }}
              onClick={() => onChange(type as any)}>
              <Icon style={{ ...style, fontSize: "28px" }} />
            </ButtonBase>
          )
      )}
    </>
  );
};

export default Tabs;
