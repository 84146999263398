import React from "react";
import ReportingComponent from "../reports/ReportingComponent";
import { useRecoilValue } from "recoil";
import { accountUserState } from "@/store/auth";
import NotFound from "@/components/layout/NotFound";

const UserHome: React.FC = () => {
  const account = useRecoilValue(accountUserState);

  if (!account?.id) return <NotFound />;

  return <ReportingComponent accountId={account.id.toString()} />;
};

export default UserHome;
