/** Build with repleek */
// #region Import
import React from "react";

import { Container, ContainerProps, Image, Typography } from "@repleek/mui";
// #endregion

// #region Type Definition
type BuildingDashboardProps = Partial<ContainerProps> & {};
// #endregion

// #region BuildingDashboardComponent
const BuildingDashboard: React.FC<BuildingDashboardProps> = (props) => {
  const { ...rootProps } = props;

  return (
    <Container
      maxWidth={"md"}
      disableGutters={false}
      fixed={false}
      css={{ textAlign: "center" }}
      {...rootProps}
    >
      <Container
        maxWidth={"md"}
        disableGutters={false}
        fixed={false}
        css={{ padding: "16px", borderRadius: "22px" }}
      >
        <Image
          alt={"Image description for better SEO"}
          src={"/images/building.svg"}
          dynamic={true}
          css={{ maxHeight: "300px" }}
        />
        <Container maxWidth={"sm"} disableGutters={false} fixed={false}>
          <Typography
            variant={"h2"}
            text={
              "Préparez-vous : Votre tableau de bord est en cours de construction !"
            }
            gutterBottom={false}
            noWrap={false}
            css={{
              fontSize: "24px",
              marginTop: "16px",
              fontWeight: 600,
              marginBottom: "8px",
            }}
          />
          <Typography
            variant={"body1"}
            text={
              "Nous mettons les dernières touches pour vous offrir une expérience \nexceptionnelle. Restez à l'écoute pour découvrir votre tout nouveau \ntableau de bord !"
            }
            gutterBottom={false}
            noWrap={false}
            css={{ color: "#5C5C60" }}
          />
        </Container>
      </Container>
    </Container>
  );
};

export default BuildingDashboard;
// #endregion
