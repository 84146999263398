import { ListReport, ReportingItem } from "@/components";
import { Modal, PistacheLoader } from "@/components/layout";
import { useStrapi } from "@/providers/StrapiProvider";
import {
  reportingListSelector,
  reportingMeta,
  reportingQuery,
} from "@/store/reporting";
import { Pagination } from "@mui/material";
import dayjs from "dayjs";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";

const ReportingPage: React.FC<{ id: string }> = ({ id }) => {
  const [query, setQuery] = useRecoilState(reportingQuery(id));
  const meta = useRecoilValue(reportingMeta(id));

  const pageCount = useMemo(
    () => (meta ? meta.pagination.pageCount || 0 : 0),
    [meta]
  );

  const handlePaginate = useCallback(
    (_event: any, page: number) => {
      setQuery((q) => ({ ...q, pagination: { ...q.pagination, page } }));
    },
    [setQuery]
  );

  return (
    <ListReport
      reporting={{
        children: (
          <Suspense fallback={<PistacheLoader />}>
            <Reporting id={id} />
          </Suspense>
        ),
      }}
      pagination={{
        children: (
          <Pagination
            sx={{
              background: "#ffffff4f",
              border: "1px solid #9a9a9a",
              borderRadius: "32px",
              padding: "8px",
            }}
            color="secondary"
            hidden={pageCount <= 1}
            count={pageCount}
            page={query.pagination.page}
            onChange={handlePaginate}
          />
        ),
      }}
      newReporting={{ href: `/${id}/new-reporting` }}
    />
  );
};

const Reporting: React.FC<{ id: string }> = ({ id: accountId }) => {
  const [strapi] = useStrapi();
  const [elToDelete, setElToDelete] = useState<
    { id: number; name?: string } | undefined
  >(undefined);

  const list = useRecoilValue(reportingListSelector(accountId));
  const [query, setQuery] = useRecoilState(reportingQuery(accountId));
  const setReportingMeta = useSetRecoilState(reportingMeta(accountId));
  const refresh = useRecoilRefresher_UNSTABLE(reportingListSelector(accountId));

  const onDelete = useCallback(async () => {
    if (elToDelete) {
      const res = await strapi?.delete("social-reports", elToDelete.id);
      if (res?.data) {
        setElToDelete(undefined);
        if (query.pagination.page > 1)
          setQuery((q) => ({ ...q, pagination: { ...q.pagination, page: 1 } }));
        else refresh();
      }
    }
  }, [elToDelete, strapi, query.pagination.page, setQuery, refresh]);

  useEffect(() => {
    setReportingMeta(list?.meta);
  }, [list?.meta, setReportingMeta]);

  return (
    <>
      {list?.data?.map(({ name, id, lastView }) => (
        <ReportingItem
          name={{ text: name }}
          key={id}
          editBtn={{
            href: `/${accountId}/edit-reporting/${id}`,
          }}
          deleteBtn={{ onClick: () => id && setElToDelete({ id, name }) }}
          viewBtn={{
            href: `/${accountId}/reporting/${id}`,
          }}
          lastView={{
            text: lastView
              ? `Consulté ${dayjs(lastView).fromNow()}`
              : "Non consulté",
          }}
        />
      ))}

      <Modal
        type="box"
        open={!!elToDelete}
        onClose={() => setElToDelete(undefined)}
        onConfirm={onDelete}
        title="Suppression de rapport"
        message={`Êtes-vous sûr de vouloir supprimer le rapport <b><u>${elToDelete?.name}</u></b> ? Cette action est irréversible et toutes les données associées seront définitivement perdues.`}
      />
    </>
  );
};

export default ReportingPage;
