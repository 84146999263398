import { NotFound } from "@/components";
import Layout from "@/components/layout";
import React from "react";

const PageNotFound: React.FC = () => {
  return (
    <Layout title="404 - Page introuvable" mode="ADMIN">
      <NotFound />
    </Layout>
  );
};

export default PageNotFound;
