/** Build with repleek */
// #region Import
import React from "react";

import { Box, BoxProps, Container } from "@repleek/mui";
// #endregion

// #region Type Definition
type UserListProps = Partial<BoxProps> & {
  users: Partial<BoxProps>,
};
// #endregion

// #region UserListComponent
const UserList: React.FC<UserListProps> = (props) => {
  const { users, ...rootProps } = props;

  return (
    <Box tag={"div"} {...rootProps}>
      <Container maxWidth={"lg"} disableGutters={false} fixed={false}>
        <Box
          tag={"div"}
          css={{
            backgroundColor: "rgba(255,255,255, 1)",
            borderRadius: "12px",
            paddingLeft: "26px",
            paddingRight: "26px",
            paddingTop: "24px",
            paddingBottom: "24px",
            height: "calc(100vh - 100px)",
            ".mui-dark &": { backgroundColor: "rgba(39, 37, 45, 1)" },
          }}
          {...users}
        />
      </Container>
    </Box>
  );
};

export default UserList;
// #endregion
