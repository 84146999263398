import React, { useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";

const Oauth: React.FC = () => {
  const { search } = useLocation();
  const { provider } = useParams();

  const params = useMemo(
    () => ({ ...queryString.parse(search), source: "oauth-popup", provider }),
    [provider, search]
  );

  useEffect(() => {
    window.opener.postMessage(params, "http://localhost:3000");
    window.opener.postMessage(params, "https://dashboard.agence-pistache.fr");
  }, [params]);

  return <></>;
};

export default Oauth;
