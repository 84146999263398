/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  Chip,
  ChipProps,
  Icon,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { GoDotFill } from "react-icons/go";
// #endregion

// #region Type Definition
type PlanningCardProps = Partial<BoxProps> & {
  name: TypographyProps & {},
  state: Partial<ChipProps>,
  socials: Partial<BoxProps>,
};
// #endregion

// #region PlanningCardComponent
const PlanningCard: React.FC<PlanningCardProps> = (props) => {
  const { name, state, socials, ...rootProps } = props;

  return (
    <Box
      tag={"div"}
      css={{
        maxWidth: "250px",
        boxShadow:
          "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        borderRadius: "8px",
        paddingLeft: "8px",
        paddingRight: "8px",
        paddingTop: "12px",
        paddingBottom: "12px",
        ":hover": {
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          userSelect: "none",
          cursor: "pointer",
        },
        ".mui-dark &": { backgroundColor: "rgba(39, 37, 45, 1)" },
      }}
      {...rootProps}
    >
      <Typography
        variant={"h1"}
        text={"Mon titre ici <span>Mon titre ici</span>"}
        gutterBottom={false}
        noWrap={false}
        css={{ fontSize: "13px", marginBottom: "12px" }}
        {...name}
      />
      <Box
        tag={"div"}
        css={{ marginBottom: "12px", gap: "5px", display: "flex" }}
        {...socials}
      />
      <Chip
        label={"Chip label"}
        avatar={false}
        icon={true}
        clickable={false}
        color={"default"}
        variant={"filled"}
        Icon={
          <Icon
            color={"inherit"}
            fontSize={"inherit"}
            icon={GoDotFill}
            className={
              "MuiChip-icon MuiChip-iconMedium MuiChip-iconColorDefault"
            }
          />
        }
        {...state}
      />
    </Box>
  );
};

export default PlanningCard;
// #endregion
