import { LayoutDefinition } from "@/interfaces";
import { layoutState } from "@/store/layout";
import { useColorMode } from "@repleek/mui";
import { useEffect } from "react";
import { useRecoilState } from "recoil";

export const useBreadCrumb = (breadcrumbs: LayoutDefinition["breadcrumbs"]) => {
  const [layout, setLayout] = useRecoilState(layoutState);

  useEffect(() => {
    setLayout({ ...layout, breadcrumbs });
    return () => {
      setLayout({ ...layout, breadcrumbs: [] });
    };
  }, []);
};

export const useThemeChange = () => {
  const { mode } = useColorMode();

  useEffect(() => {
    const body = document.querySelector("body");
    if (mode === "dark") body?.classList.add("mui-dark");
    else body?.classList.remove("mui-dark");
  }, [mode]);
};
