import React from "react";
import LoginPage from "@/components/features/auth/LoginPage";
import Layout from "@/components/layout";

const Login: React.FC = () => {
  return (
    <Layout title="Pistache - connexion" mode="LOGGED_OUT" redirect="/">
      <LoginPage />
    </Layout>
  );
};

export default Login;
