/** Build with repleek */
// #region Import
import React from "react";

import {
  Container,
  ContainerProps,
  Image,
  Paper,
  Typography,
} from "@repleek/mui";
// #endregion

// #region Type Definition
type NotFoundProps = Partial<ContainerProps> & {};
// #endregion

// #region NotFoundComponent
const NotFound: React.FC<NotFoundProps> = (props) => {
  const { ...rootProps } = props;

  return (
    <Container
      maxWidth={"md"}
      disableGutters={false}
      fixed={false}
      css={{ textAlign: "center" }}
      {...rootProps}
    >
      <Paper
        square={true}
        variant={"elevation"}
        elevation={1}
        css={{
          padding: "32px",
          boxShadow:
            "rgba(240, 46, 170, 0.4) 5px 5px , rgba(240, 46, 170, 0.3) 10px 10px , rgba(240, 46, 170, 0.2) 15px 15px , rgba(240, 46, 170, 0.1) 20px 20px , rgba(240, 46, 170, 0.05) 25px 25px",
          borderRadius: "22px",
        }}
      >
        <Image
          alt={"Image description for better SEO"}
          src={"/images/not-found.svg"}
          dynamic={true}
          css={{ maxHeight: "300px" }}
        />
        <Typography
          variant={"h2"}
          text={"Page introuvable"}
          gutterBottom={false}
          noWrap={false}
          css={{
            fontSize: "32px",
            textTransform: "uppercase",
            marginTop: "16px",
          }}
        />
      </Paper>
    </Container>
  );
};

export default NotFound;
// #endregion
