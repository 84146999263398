import { selectorFamily } from "recoil";
import { socialsAuthState } from "./auth";
import { Provider } from "@/interfaces";
import dayjs from "dayjs";
import { strapiInstance } from "@/providers/StrapiProvider";

export const socialJwtState = selectorFamily({
  key: "social-jwt-state",
  get:
    (provider: Provider) =>
    ({ get }) => {
      const jwt = get(socialsAuthState(provider));
      if (!jwt) return null;

      if (jwt.expires_in) {
        const expires_in = dayjs(jwt.expires_in);
        if (expires_in.isBefore(dayjs().subtract(2, "day"), "day")) return null;
      }
      return jwt;
    },
});

export const socialPageAttached = selectorFamily({
  key: "social-page-attached-state",
  get:
    (provider: Provider) =>
    async ({ get }) => {
      const jwt = get(socialJwtState(provider));
      if (!jwt) return null;
      return await strapiInstance.post(
        "me/accounts/:provider/social-pages",
        { ":provider": provider },
        jwt
      );
    },
});
