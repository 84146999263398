import { ReactElement } from "react";
import Home from "./Home";
import Login from "./Login";
import ResetPassword from "./ResetPassword";
import NewAccount from "./new-account";
import Account from "./account";
import PageNotFound from "./PageNotFound";
import Oauth from "./Oauth";
import NewReport from "./NewReport";
import Reporting from "./Reporting";
import Users from "./Users";
import Accounts from "./Accounts";

import { FaChartPie, FaUsers, FaFolder } from "react-icons/fa6";

const pages: { path: string; page: ReactElement }[] = [
  {
    path: "*",
    page: <PageNotFound />,
  },
  {
    path: "/",
    page: <Home />,
  },
  {
    path: "/accounts",
    page: <Accounts />,
  },
  {
    path: "/login",
    page: <Login />,
  },
  {
    path: "/reset-password",
    page: <ResetPassword />,
  },
  {
    path: "/new-account",
    page: <NewAccount />,
  },
  {
    path: "/users",
    page: <Users />,
  },
  {
    path: "/account/:id",
    page: <Account />,
  },
  {
    path: "/account/:id/:page",
    page: <Account />,
  },
  {
    path: "/edit-account/:id",
    page: <NewAccount />,
  },
  {
    path: "/oauth/:provider",
    page: <Oauth />,
  },
  {
    path: "/:accountId/new-reporting",
    page: <NewReport />,
  },
  {
    path: "/:accountId/reporting/:id",
    page: <Reporting />,
  },
  {
    path: "/:accountId/edit-reporting/:id",
    page: <NewReport />,
  },
];

export const navigations = {
  admin: [
    {
      label: "Dashboard",
      href: "/",
      icon: FaChartPie,
    },
    {
      label: "Comptes",
      href: "/accounts",
      icon: FaFolder,
    },
    {
      label: "Utilisateurs",
      href: "/users",
      icon: FaUsers,
    },
  ],
  customer: [],
  default: [],
};

export default pages;
