/** Build with repleek */
// #region Import
import React from "react";

import {
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Image,
  Paper,
  Typography,
} from "@repleek/mui";
// #endregion

// #region Type Definition
type EmptyResultProps = Partial<ContainerProps> & {
  action: ButtonProps & {},
};
// #endregion

// #region EmptyResultComponent
const EmptyResult: React.FC<EmptyResultProps> = (props) => {
  const { action, ...rootProps } = props;

  return (
    <Container
      maxWidth={"md"}
      disableGutters={false}
      fixed={false}
      css={{ textAlign: "center" }}
      {...rootProps}
    >
      <Paper
        square={true}
        variant={"elevation"}
        elevation={1}
        css={{ padding: "22px", borderRadius: "22px" }}
      >
        <Image
          alt={"Image description for better SEO"}
          src={"/images/welcome.svg"}
          dynamic={true}
          css={{ maxHeight: "280px" }}
        />
        <Typography
          variant={"h2"}
          text={"No data found"}
          gutterBottom={false}
          noWrap={false}
          css={{
            fontSize: "32px",
            textTransform: "uppercase",
            marginBottom: "16px",
          }}
        />
        <Button
          text={"Creer un compte"}
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          variant={"contained"}
          color={"secondary"}
          iconStart={false}
          iconEnd={false}
          {...action}
        />
      </Paper>
    </Container>
  );
};

export default EmptyResult;
// #endregion
