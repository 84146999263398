import React, { useState } from "react";
import { ButtonBase, Container } from "@mui/material";
import ImportInsights from "@/components/features/reports/ImportInsights";
import { socialIcons } from "@/components/features/account/SocialDetail";
import { useSocialAccess } from "@/hooks/account";
import { accountSelector } from "@/store/accounts";
import { useRecoilValue } from "recoil";
import { Modal } from "@/components/layout";
import SocialStatisticTable from "@/components/features/account/SocialStatisticTable";

const Insight: React.FC<{ id: string }> = ({ id }) => {
  const account = useRecoilValue(accountSelector(id));
  const socialAccess = useSocialAccess(account?.configuration) as any;

  const [showStatModal, setSetshowStatModal] = useState<
    keyof typeof socialIcons | undefined
  >(undefined);
  return (
    <>
      <Container
        maxWidth="md"
        sx={{ display: "flex", justifyContent: "center", gap: "16px" }}>
        {Object.entries(socialIcons).map(
          ([
            type,
            {
              icon: { icon: Icon, style },
              title,
            },
          ]) =>
            Icon &&
            socialAccess[type] && (
              <ButtonBase
                key={title}
                sx={{
                  width: "144px",
                  height: "144px",
                  borderRadius: "16px",
                  background: "#ffffff33",
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
                onClick={() =>
                  setSetshowStatModal(type as keyof typeof socialIcons)
                }>
                <Icon style={{ ...style, fontSize: "48px" }} />
                <span>{title}</span>
              </ButtonBase>
            )
        )}
      </Container>

      <ImportInsights id={id} />

      <Modal
        type="box"
        open={!!showStatModal}
        title={`Daily ${showStatModal} insight`}
        icon={showStatModal && socialIcons[showStatModal].icon}
        onClose={() => setSetshowStatModal(undefined)}
        noActions
        width="md">
        <SocialStatisticTable id={account?.id} type={showStatModal} />
      </Modal>
    </>
  );
};

export default Insight;
