import React, { Suspense, useCallback, useMemo } from "react";
import { RepportingForm, NotFound } from "@/components";
import Layout, { PistacheLoader } from "@/components/layout";
import { SocialReport } from "@/modules/strapi-sdk/lib/interfaces";
import { useNavigate } from "@repleek/mui";
import { useParams } from "react-router-dom";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";
import MounthPicker from "@/components/fields/MounthPicker";
import dayjs, { Dayjs } from "dayjs";
import StatisticForm from "@/components/features/reports/StatisticForm";
import { reportingListSelector, reportingSelector } from "@/store/reporting";
import { useStrapi } from "@/providers/StrapiProvider";
import { toast } from "react-toastify";
import { useSocialAccess } from "@/hooks/account";
import { accountSelector } from "@/store/accounts";

const NewReport: React.FC = () => {
  return (
    <Layout title="Pistache - Nouveau compte rendu" mode="ADMIN">
      <Suspense fallback={<PistacheLoader />}>
        <Form />
      </Suspense>
    </Layout>
  );
};

const defaultStats = {
  followers: 0,
  impressions: 0,
  posts: 0,
  interactions: 0,
  engagement: 0,
  comments: 0,
  reactions_likes: 0,
};

const Form: React.FC = () => {
  const { id, accountId } = useParams() as any;

  const [strapi] = useStrapi();
  const { navigate } = useNavigate();
  const reporting = useRecoilValue(reportingSelector({ id, accountId }));
  const account = useRecoilValue(accountSelector(accountId));
  const config = useSocialAccess(account?.configuration);

  const refresh = useRecoilRefresher_UNSTABLE(
    reportingSelector({ id, accountId })
  );
  const refreshList = useRecoilRefresher_UNSTABLE(
    reportingListSelector(accountId)
  );

  const maxDate = useMemo(
    () => dayjs().subtract(1, "month").endOf("month"),
    []
  );

  const defaultValues = useMemo<Partial<SocialReport>>(
    () =>
      id
        ? {
            ...reporting,
            period: reporting?.date ? dayjs(reporting.date) : maxDate,
          }
        : {
            name: `Compte rendu du mois de ${maxDate.format("MMMM YYYY")}`,
            period: maxDate,
            facebook: defaultStats,
            instagram: defaultStats,
            tiktok: defaultStats,
            linkedin: defaultStats,
          },
    [id, maxDate, reporting]
  );

  const onSubmit = useCallback(
    async ({ period, ...value }: SocialReport & { period: Dayjs }) => {
      let newReport: SocialReport | undefined;
      if (!id) {
        // create
        const date = period.format("YYYY-MM-01");
        const res = await strapi?.create("social-reports", {
          ...value,
          account: accountId,
          date,
          key: `${accountId}|${date}`,
        });
        newReport = res?.data;
      } else {
        // update
        const res = await strapi?.update("social-reports", id, value);
        newReport = res?.data;
      }
      if (newReport) {
        navigate?.(`/${accountId}/reporting/${newReport.id}`);
        toast(id ? "Rapport mise à jour" : "Rapport créé avec succès", {
          type: "success",
        });
        refresh();
        refreshList();
      }
    },
    [accountId, id, navigate, refresh, refreshList, strapi]
  );

  if (!reporting && id) return <NotFound />;

  return (
    <RepportingForm
      title={{ text: id ? "Mise à jour" : "Nouveau compte rendu" }}
      form={{ onSubmit, defaultValues }}
      facebook={{
        children: config.facebook && <StatisticForm type={"facebook"} />,
      }}
      instagram={{
        children: config.instagram && <StatisticForm type={"instagram"} />,
      }}
      tiktok={{
        children: config.tiktok && <StatisticForm type={"tiktok"} />,
      }}
      linkedin={{
        children: config.linkedin && <StatisticForm type={"linkedin"} />,
      }}
      date={{
        children: accountId && (
          <MounthPicker
            accountId={accountId}
            defaultPeriod={reporting?.date}
            maxDate={maxDate}
          />
        ),
      }}
      facebookTitle={{ hidden: !config.facebook }}
      tiktokTitle={{ hidden: !config.tiktok }}
      instagramTitle={{ hidden: !config.instagram }}
      linkedinTitle={{ hidden: !config.linkedin }}
    />
  );
};

export default NewReport;
