import { TextField } from "@mui/material";
import React from "react";
import { useWatch } from "react-hook-form";

const BasicInfo: React.FC = () => {
  const name = useWatch({ name: "name" });
  const email = useWatch({ name: "email" });
  const notionId = useWatch({ name: "notion_id" });

  return (
    notionId && (
      <>
        <TextField
          variant="outlined"
          label="Nom du compte"
          value={name}
          required
          fullWidth
          disabled
        />
        <TextField
          variant="outlined"
          label="E-mail"
          value={email}
          required
          fullWidth
          disabled
        />
      </>
    )
  );
};

export default BasicInfo;
