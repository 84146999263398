import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useCalendarApp, ScheduleXCalendar } from "@schedule-x/react";
import { createViewMonthGrid } from "@schedule-x/calendar";
import { createEventsServicePlugin } from "@schedule-x/events-service";
import { useColorMode } from "@repleek/mui";
import { Container } from "@mui/material";

import "@schedule-x/theme-default/dist/index.css";
import "./style.css";
import { PlanningCard } from "@/components";
import PlanningDrawer from "./PlanningDrawer";
import { socialIcons } from "@/components/features/account/SocialDetail";
import { useRecoilValue } from "recoil";
import { postSelector } from "@/store/post";
import { Post } from "@/modules/strapi-sdk/lib/interfaces";
import dayjs from "dayjs";
import { notionOptionByValue } from "@/store/notion";

const Planning: React.FC<{ id: string }> = ({ id }) => {
  const plugins = [createEventsServicePlugin()];

  const { mode } = useColorMode();
  const [selectedPost, setSelectedPost] = useState<Post | undefined>();
  const [range, setRange] = useState<{ start: string; end: string }>({
    start: dayjs().startOf("M").format(),
    end: dayjs().endOf("M").format(),
  });
  const posts = useRecoilValue(
    postSelector({
      id,
      range,
    })
  );

  const events = useMemo(
    () =>
      posts?.map(({ scheduledSendTime, ...rest }) => ({
        start: scheduledSendTime,
        end: scheduledSendTime,
        scheduledSendTime,
        ...rest,
      })),
    [posts]
  ) as any[];

  const onSelectedDateUpdate = useCallback((date: string) => {
    setRange({
      start: dayjs(date).startOf("M").format(),
      end: dayjs(date).endOf("M").format(),
    });
  }, []);

  const onEventClick = useCallback((post: Post) => {
    setSelectedPost(post);
  }, []) as () => void;

  const calendar = useCalendarApp(
    {
      isDark: mode === "dark",
      locale: "fr-FR",
      views: [createViewMonthGrid()],
      events,
      callbacks: {
        onEventClick,
        onClickDate(date: any) {
          console.log("onClickDate", date); // e.g. 2024-01-01
        },
        onSelectedDateUpdate,
        onClickAgendaDate(date: string) {
          console.log("onClickAgendaDate", date); // e.g. 2024-01-01
        },
      },
    },
    plugins
  );

  useEffect(() => {
    // get all events
    const allEvents = calendar.eventsService.getAll() as any[];
    events.forEach((event) => {
      if (!allEvents.find(({ id }) => id === event.id)) {
        calendar.eventsService.add(event);
      }
    });
  }, [calendar.eventsService, events]);

  useEffect(() => {
    calendar.setTheme(mode === "dark" ? "dark" : "light");
  }, [calendar, mode]);

  return (
    <>
      <Container>
        <ScheduleXCalendar
          calendarApp={calendar}
          customComponents={{
            monthGridEvent: TimeGridEvent,
          }}
        />
      </Container>
      <PlanningDrawer
        post={selectedPost}
        onClose={() => setSelectedPost(undefined)}
        id={id}
      />
    </>
  );
};

export default Planning;

const TimeGridEvent: React.FC<{ calendarEvent: Post }> = (props) => {
  const { title, socialProfileIs, status } = props.calendarEvent;
  const option = useRecoilValue(
    notionOptionByValue({
      key: status,
      collection: "livrables",
    })
  );

  const socials = useMemo(() => {
    const socials: any[] = [];
    if (socialProfileIs) {
      const { facebook, instagram, linkedin, tiktok } = socialProfileIs;
      if (facebook) socials.push("facebook");
      if (instagram) socials.push("instagram");
      if (linkedin) socials.push("linkedin");
      if (tiktok) socials.push("tiktok");
    }
    return socials;
  }, [socialProfileIs]);

  return (
    <PlanningCard
      socials={{
        children: <SocialIcon icons={socials} />,
      }}
      state={{
        className: option?.color,
        color: "info",
        variant: "outlined",
        label: option?.label,
        hidden: !option,
      }}
      name={{
        text: title,
      }}
    />
  );
};

const SocialIcon: React.FC<{ icons: (keyof typeof socialIcons)[] }> = (
  props
) => {
  const icons = useMemo(
    () => props.icons.map((icon) => socialIcons[icon]),
    [props.icons]
  );
  return (
    <>
      {icons.map(
        ({ icon: { icon: Icon, style }, title }) =>
          Icon && <Icon key={title} style={{ ...style, fontSize: "18px" }} />
      )}
    </>
  );
};
