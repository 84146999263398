import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { API_URL } from "@/constant";
import Strapi from "@/modules/strapi-sdk";
import { useSetRecoilState } from "recoil";
import { authState } from "@/store/auth";

const StrapiContext = createContext<[Strapi | null, { loading?: boolean }]>([
  null,
  {},
]);

export const strapiInstance = new Strapi({
  url: API_URL,
  store: {
    key: "strapi_jwt",
    useLocalStorage: true,
    cookieOptions: { path: "/" },
  },
  axiosOptions: {
    headers: {
      "ngrok-skip-browser-warning": "69420",
    },
  },
});

export const StrapiProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [strapi, setStrapi] = useState<Strapi | null>(null);
  const [loading, setLoading] = useState(false);
  const setUser = useSetRecoilState(authState);

  useEffect(() => {
    if (!strapi) {
      strapiInstance.options.events.onAuthChange = (user) => setUser(user);
      strapiInstance.onLoading = (state) =>
        setTimeout(() => {
          setLoading(state);
        }, 50);
      setStrapi(strapiInstance);
    }
  }, [setUser, strapi]);

  return (
    <StrapiContext.Provider value={[strapi, { loading }]}>
      {children}
    </StrapiContext.Provider>
  );
};

export const useStrapi = () => useContext(StrapiContext);
