import React, { useCallback, useEffect, useMemo, useState } from "react";
import AccoutItem from "./AccoutItem";
import { Pagination } from "@mui/material";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { accountQuery, accountsSelector } from "@/store/accounts";
import { Modal } from "@/components/layout";
import { useStrapi } from "@/providers/StrapiProvider";
import EmptyResult from "@/components/layout/EmptyResult";
import { IconProps } from "@repleek/mui";
import {
  IoBriefcaseOutline,
  IoHelp,
  IoRestaurantOutline,
} from "react-icons/io5";

type AccountType = "classic" | "restaurant" | "default";

export const accountType: Record<
  AccountType,
  { label: string; icon: IconProps["icon"] }
> = {
  classic: {
    label: "Classique",
    icon: IoBriefcaseOutline,
  },
  restaurant: {
    label: "Restaurant",
    icon: IoRestaurantOutline,
  },
  default: {
    label: "Non defini",
    icon: IoHelp,
  },
};

export const AccountList: React.FC<{
  setCount: (count: number) => void;
}> = ({ setCount }) => {
  const accounts = useRecoilValue(accountsSelector);
  const [query, setQuery] = useRecoilState(accountQuery);

  const [strapi] = useStrapi();

  const pageCount = useMemo(
    () => (accounts ? accounts.meta.pagination.pageCount || 0 : 0),
    [accounts]
  );

  const [elToDelete, setElToDelete] = useState<
    { id: number; name: string } | undefined
  >(undefined);
  const refreshAccounts = useRecoilRefresher_UNSTABLE(accountsSelector);

  const onDeleteAccount = useCallback(async () => {
    if (elToDelete) {
      const res = await strapi?.delete("me/accounts", elToDelete.id);
      if (res?.data) {
        setElToDelete(undefined);
        if (query.pagination.page > 1)
          setQuery((q) => ({ ...q, pagination: { ...q.pagination, page: 1 } }));
        else refreshAccounts();
      }
    }
  }, [elToDelete, strapi, query.pagination.page, setQuery, refreshAccounts]);

  useEffect(() => {
    setCount(pageCount);
  }, [pageCount, setCount]);

  if (!accounts?.data?.length)
    return <EmptyResult action={{ href: "/new-account" }} />;

  return (
    <>
      {accounts?.data?.map(({ name, id, email, type, url }) => (
        <AccoutItem
          name={{ text: name }}
          key={id}
          editBtn={{
            href: `/edit-account/${id}`,
          }}
          deleteBtn={{
            onClick: () => id && setElToDelete({ id, name }),
          }}
          viewBtn={{
            href: `/account/${id}`,
          }}
          logo={{
            src: url || "/images/logo-pistache.jpg",
          }}
          email={{
            text: email,
            hidden: !email,
          }}
          type={{
            label:
              accountType[type as AccountType]?.label ||
              accountType.default.label,
          }}
          typeIcon={{
            icon:
              accountType[type as AccountType]?.icon ||
              accountType.default.icon,
          }}
        />
      ))}

      <Modal
        type="box"
        open={!!elToDelete}
        onClose={() => setElToDelete(undefined)}
        onConfirm={onDeleteAccount}
        title="Suppression de compte"
        message={`Êtes-vous sûr de vouloir supprimer le compte de <b><u>${elToDelete?.name}</u></b> ? Cette action est irréversible et toutes les données associées seront définitivement perdues.`}
      />
    </>
  );
};

export const AccountPagination: React.FC<{ count: number }> = ({ count }) => {
  const [query, setQuery] = useRecoilState(accountQuery);

  const handlePaginate = useCallback(
    (_event: any, page: number) => {
      setQuery((q) => ({ ...q, pagination: { ...q.pagination, page } }));
    },
    [setQuery]
  );
  return (
    <Pagination
      color="secondary"
      sx={{
        background: "#ffffff4f",
        border: "1px solid #9a9a9a",
        borderRadius: "32px",
        padding: "8px",
      }}
      hidden={count <= 1}
      count={count}
      page={query.pagination.page}
      onChange={handlePaginate}
    />
  );
};
