import { PaginationByPage } from "@/modules/strapi-sdk";
import { strapiInstance } from "@/providers/StrapiProvider";
import { atom, selector } from "recoil";
import { authState } from "./auth";

export const notificationModal = atom<boolean>({
  key: "notification-modal",
  default: false,
});

export const notificationQuery = atom<{
  pagination: PaginationByPage;
  archived: boolean;
}>({
  key: "notification-query",
  default: {
    pagination: {
      page: 1,
      pageSize: 20,
      withCount: true,
    },
    archived: false,
  },
});

export const notificationsSelector = selector({
  key: "notifications-selector",
  get: async ({ get }) => {
    const user = get(authState);
    const { pagination, archived } = get(notificationQuery);
    if (user)
      return await strapiInstance.find("notifications", {
        pagination,
        filters: {
          archived: {
            $eq: archived,
          },
        },
        fields: ["*"],
        populate: ["creator", "action"],
        sort: ["createdAt:DESC"],
      });
  },
});
