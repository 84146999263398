/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  Button,
  ButtonIcon,
  ButtonIconProps,
  ButtonProps,
  Container,
  ContainerProps,
  Icon,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { HiOutlineCog6Tooth } from "react-icons/hi2";
import { TfiTrash } from "react-icons/tfi";
// #endregion

// #region Type Definition
type ReportingItemProps = Partial<ContainerProps> & {
  editBtn: Partial<ButtonIconProps>,
  deleteBtn: Partial<ButtonIconProps>,
  name: TypographyProps & {},
  viewBtn: ButtonProps & {},
  lastView: TypographyProps & {},
};
// #endregion

// #region ReportingItemComponent
const ReportingItem: React.FC<ReportingItemProps> = (props) => {
  const { editBtn, deleteBtn, name, viewBtn, lastView, ...rootProps } = props;

  return (
    <Container
      maxWidth={"md"}
      disableGutters={false}
      fixed={false}
      css={{
        padding: "12px",
        boxShadow:
          "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        gap: "12px",
        borderRadius: "22px",
        marginBottom: "12px",
        display: "flex",
        alignItems: "center",
        ".mui-dark &": {
          boxShadow:
            "rgba(255, 251, 251, 0.02) 0px 1px 3px 0px  , rgba(252, 252, 252, 0.15) 0px 0px 0px 1px ",
        },
      }}
      {...rootProps}
    >
      <Box tag={"div"} css={{ flexGrow: "1", paddingLeft: "12px" }}>
        <Typography
          variant={"h4"}
          text={"Customer 1"}
          gutterBottom={false}
          noWrap={false}
          css={{ fontSize: "18px" }}
          {...name}
        />
        <Typography
          variant={"overline"}
          text={"Consulté il y a 10 min"}
          gutterBottom={false}
          noWrap={false}
          {...lastView}
        />
      </Box>
      <Button
        text={"Consulter"}
        type={"button"}
        disabled={false}
        disableElevation={false}
        disableFocusRipple={false}
        disableRipple={false}
        fullWidth={false}
        variant={"contained"}
        iconStart={false}
        iconEnd={false}
        css={{
          paddingLeft: "8px",
          paddingRight: "8px",
          paddingTop: "4px",
          paddingBottom: "4px",
        }}
        {...viewBtn}
      />
      <ButtonIcon
        type={"button"}
        disabled={false}
        disableFocusRipple={false}
        disableRipple={false}
        size={"medium"}
        color={"secondary"}
        Icon={<Icon color={"inherit"} fontSize={"inherit"} icon={TfiTrash} />}
        {...deleteBtn}
      />
      <ButtonIcon
        type={"button"}
        disabled={false}
        disableFocusRipple={false}
        disableRipple={false}
        size={"medium"}
        color={"primary"}
        Icon={
          <Icon
            color={"inherit"}
            fontSize={"inherit"}
            icon={HiOutlineCog6Tooth}
          />
        }
        {...editBtn}
      />
    </Container>
  );
};

export default ReportingItem;
// #endregion
