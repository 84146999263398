/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  Container,
  ContainerProps,
  Typography,
} from "@repleek/mui";
// #endregion

// #region Type Definition
type NewReportModalProps = Partial<ContainerProps> & {
  fileUploader: Partial<BoxProps>,
};
// #endregion

// #region NewReportModalComponent
const NewReportModal: React.FC<NewReportModalProps> = (props) => {
  const { fileUploader, ...rootProps } = props;

  return (
    <Container
      maxWidth={"sm"}
      disableGutters={false}
      fixed={false}
      css={{ paddingTop: "16px", paddingBottom: "16px", userSelect: "none" }}
      {...rootProps}
    >
      <Box
        tag={"div"}
        css={{ flexGrow: "1", marginBottom: "12px", textAlign: "center" }}
      >
        <Typography
          variant={"h3"}
          text={"Importer depuis Hootsuite"}
          gutterBottom={false}
          noWrap={false}
          css={{ fontSize: "18px", marginBottom: "8px" }}
        />
        <Typography
          variant={"body1"}
          text={
            "Importez des données depuis Hootsuite à partir d'un fichier ZIP contenant des fichiers CSV exportés."
          }
          gutterBottom={false}
          noWrap={false}
        />
      </Box>
      <Box tag={"div"} {...fileUploader} />
    </Container>
  );
};

export default NewReportModal;
// #endregion
