import React, { useMemo } from "react";
import { Tab } from "./Tabs";

const HashTags: React.FC<{ tags: string[]; tab: Tab }> = ({ tags, tab }) => {
  //https://www.instagram.com/explore/tags/
  const tagLabels = useMemo(
    () =>
      tags.map((tag) => {
        let href = "";
        switch (tab) {
          case "facebook":
            href = `https://www.facebook.com/hashtag/${tag.replace("#", "")}`;
            break;
          case "instagram":
            href = `https://www.instagram.com/explore/tags/${tag.replace(
              "#",
              ""
            )}`;
            break;
        }
        return { href, label: tag };
      }),
    [tab, tags]
  );
  return (
    <>
      {tagLabels.map(({ href, label }) => (
        <a href={href} target="_blank" rel="noopener noreferrer" key={label}>
          {label}
        </a>
      ))}
    </>
  );
};

export default HashTags;
