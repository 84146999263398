import { Chip } from "@mui/material";
import React from "react";
import { useWatch } from "react-hook-form";

const SocialBadge: React.FC = () => {
  const { facebook, instagram, linkedin, tiktok } = useWatch({
    name: "configuration",
  });
  const notionId = useWatch({
    name: "notion_id",
  });

  return (
    notionId && (
      <>
        {facebook && <Chip label="Facebook" variant="outlined" />}
        {instagram && <Chip label="Instagram" variant="outlined" />}
        {linkedin && <Chip label="LinkedIn" variant="outlined" />}
        {tiktok && <Chip label="TikTok" variant="outlined" />}
      </>
    )
  );
};

export default SocialBadge;
