/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  TextField,
  TextFieldProps,
} from "@repleek/mui";
// #endregion

// #region Type Definition
type EmailFormProps = Partial<ContainerProps> & {
  email: Partial<TextFieldProps>,
  back: ButtonProps & {},
};
// #endregion

// #region EmailFormComponent
const EmailForm: React.FC<EmailFormProps> = (props) => {
  const { email, back, ...rootProps } = props;

  return (
    <Container
      maxWidth={"xs"}
      disableGutters={false}
      fixed={false}
      {...rootProps}
    >
      <TextField
        inputName={"email"}
        label={"Destinataire"}
        variant={"outlined"}
        color={"primary"}
        type={"email"}
        aria-label={"Input Base Mui"}
        autoFocus={false}
        disabled={false}
        fullWidth={true}
        maxRows={1}
        minRows={1}
        rows={1}
        multiline={false}
        readOnly={false}
        required={true}
        size={"small"}
        css={{ marginBottom: "12px" }}
        {...email}
      />
      <TextField
        inputName={"subject"}
        label={"Objet"}
        variant={"outlined"}
        color={"primary"}
        type={"text"}
        aria-label={"Input Base Mui"}
        autoFocus={false}
        disabled={false}
        fullWidth={true}
        maxRows={1}
        minRows={1}
        rows={1}
        multiline={false}
        readOnly={false}
        required={true}
        size={"small"}
        css={{ marginBottom: "12px" }}
      />
      <TextField
        inputName={"title"}
        label={"Titre"}
        variant={"outlined"}
        color={"primary"}
        type={"text"}
        aria-label={"Input Base Mui"}
        autoFocus={false}
        disabled={false}
        fullWidth={true}
        maxRows={1}
        minRows={1}
        rows={1}
        multiline={false}
        readOnly={false}
        required={false}
        size={"small"}
        css={{ marginBottom: "12px" }}
      />
      <TextField
        inputName={"message"}
        label={"Message"}
        variant={"outlined"}
        color={"primary"}
        type={"text"}
        aria-label={"Input Base Mui"}
        autoFocus={false}
        disabled={false}
        fullWidth={true}
        maxRows={"5"}
        minRows={"3"}
        rows={"3"}
        multiline={true}
        readOnly={false}
        required={false}
        size={"small"}
        css={{ marginBottom: "12px" }}
      />
      <Box tag={"div"} css={{ gap: "8px", display: "flex" }}>
        <TextField
          inputName={"button_name"}
          label={"Ancre"}
          variant={"outlined"}
          color={"secondary"}
          type={"text"}
          aria-label={"Input Base Mui"}
          autoFocus={false}
          disabled={false}
          fullWidth={true}
          maxRows={1}
          minRows={1}
          rows={1}
          multiline={false}
          readOnly={false}
          required={true}
          size={"small"}
          css={{ width: "280px", marginBottom: "12px" }}
        />
        <TextField
          inputName={"link"}
          label={"Lien"}
          variant={"outlined"}
          color={"secondary"}
          type={"text"}
          aria-label={"Input Base Mui"}
          autoFocus={false}
          disabled={false}
          fullWidth={true}
          maxRows={1}
          minRows={1}
          rows={1}
          multiline={false}
          readOnly={false}
          required={true}
          size={"small"}
          css={{ marginBottom: "12px" }}
        />
      </Box>
      <Box
        tag={"div"}
        css={{
          marginTop: "12px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          text={"Annuler"}
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          size={"small"}
          variant={"contained"}
          color={"secondary"}
          iconStart={false}
          iconEnd={false}
          css={{
            paddingLeft: "16px",
            paddingRight: "16px",
            paddingTop: "6px",
            paddingBottom: "6px",
          }}
          {...back}
        />
        <Button
          text={"Valider"}
          type={"submit"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          size={"small"}
          variant={"contained"}
          iconStart={false}
          iconEnd={false}
          css={{
            paddingLeft: "16px",
            paddingRight: "16px",
            paddingTop: "6px",
            paddingBottom: "6px",
          }}
        />
      </Box>
    </Container>
  );
};

export default EmailForm;
// #endregion
