/** Build with repleek */
// #region Import
import React from "react";

import { Box, BoxProps, Container, Typography } from "@repleek/mui";
// #endregion

// #region Type Definition
type LoginLayoutProps = Partial<BoxProps> & {
  page: Partial<BoxProps>,
};
// #endregion

// #region LoginLayoutComponent
const LoginLayout: React.FC<LoginLayoutProps> = (props) => {
  const { page, ...rootProps } = props;

  return (
    <Box
      tag={"div"}
      css={{
        height: "calc(100vh - 100px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      {...rootProps}
    >
      <Box
        tag={"div"}
        css={{
          minWidth: "1020px",
          minHeight: "600px",
          backgroundColor: "rgba(255,255,255, 1)",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px , rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
          overflow: "hidden",
          borderRadius: "16px",
          display: "flex",
          ".mui-dark &": { backgroundColor: "rgb(39, 37, 45)" },
          "@media (max-width: 980px)": { minWidth: "780px" },
          "@media (max-width: 700px)": {
            minWidth: "400px",
            minHeight: "500px",
          },
          "@media (max-width: 480px)": { minWidth: "340px" },
        }}
      >
        <Box
          tag={"div"}
          css={{
            flexGrow: "1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          {...page}
        />
        <Box
          tag={"div"}
          css={{
            width: "55%",
            background:
              "url(/images/bg-app.jpg) center / cover no-repeat scroll",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            ".mui-dark &": {
              background:
                "url(/images/bg-app-dark.jpg) center / cover no-repeat scroll",
            },
            "@media (max-width: 700px)": { display: "none" },
          }}
        >
          <Container maxWidth={"md"} disableGutters={false} fixed={false}>
            <Typography
              variant={"h1"}
              text={
                "\nL’agence social media qui <b>booste</b> vos réseaux sociaux\n"
              }
              gutterBottom={false}
              noWrap={false}
              css={{
                fontSize: "48px",
                textAlign: "center",
                b: {
                  color: "rgba(255,255,255, 1)",
                  backgroundColor: "rgba(11, 253, 119, 1)",
                  paddingLeft: "12px",
                  paddingRight: "12px",
                  textTransform: "uppercase",
                  borderRadius: "14px",
                },
              }}
            />
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginLayout;
// #endregion
