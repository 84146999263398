import React from "react";
import StatisticFields from "./StatisticFields";
import { Provider } from "@/interfaces";

type Props = {
  type: Provider;
};

const StatisticForm: React.FC<Props> = (props) => {
  const { type } = props;
  return (
    <StatisticFields
      followers={{ inputName: `${type}.followers` }}
      posts={{ inputName: `${type}.posts` }}
      impressions={{ inputName: `${type}.impressions` }}
      interactions={{ inputName: `${type}.interactions` }}
      engagement={{
        inputName: `${type}.engagement`,
        inputProps: { inputMode: "decimal", step: 0.01 },
      }}
      comment={{ inputName: `${type}.comment` }}
      likes={{ inputName: `${type}.reactions_likes` }}
      comments={{ inputName: `${type}.comments` }}
    />
  );
};

export default StatisticForm;
