/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  ButtonIcon,
  ButtonIconProps,
  Chip,
  ChipProps,
  Icon,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { SiNotion } from "react-icons/si";
// #endregion

// #region Type Definition
type PreviewPostProps = Partial<BoxProps> & {
  name: TypographyProps & {},
  socialTab: Partial<BoxProps>,
  notion: Partial<ButtonIconProps>,
  status: Partial<ChipProps>,
  body: Partial<BoxProps>,
};
// #endregion

// #region PreviewPostComponent
const PreviewPost: React.FC<PreviewPostProps> = (props) => {
  const { name, socialTab, notion, status, body, ...rootProps } = props;

  return (
    <Box
      tag={"div"}
      css={{ padding: "12px", maxWidth: "460px", userSelect: "none" }}
      {...rootProps}
    >
      <Box
        tag={"div"}
        css={{
          padding: "8px",
          gap: "8px",
          marginBottom: "16px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          variant={"h3"}
          text={"Mon titre ici"}
          gutterBottom={false}
          noWrap={false}
          css={{ fontSize: "18px", flexGrow: "1" }}
          {...name}
        />
        <Chip
          label={"Chip label"}
          avatar={false}
          icon={false}
          clickable={false}
          color={"default"}
          variant={"filled"}
          {...status}
        />
        <ButtonIcon
          type={"button"}
          disabled={false}
          disableFocusRipple={false}
          disableRipple={false}
          Icon={<Icon color={"inherit"} fontSize={"inherit"} icon={SiNotion} />}
          {...notion}
        />
      </Box>
      <Box
        tag={"div"}
        css={{ display: "flex", justifyContent: "space-around" }}
      >
        <Box
          tag={"div"}
          css={{
            marginBottom: "16px",
            gap: "8px",
            paddingLeft: "12px",
            paddingRight: "16px",
            display: "flex",
            flexDirection: "column",
            ".active": { boxShadow: "rgba(3, 102, 214, 0.3) 0px 0px 0px 3px" },
          }}
          {...socialTab}
        />
        <Box
          tag={"div"}
          css={{
            width: "331px",
            height: "600px",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px , rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
            overflowY: "auto",
            position: "relative",
            paddingLeft: "12px",
            paddingRight: "12px",
            paddingTop: "34px",
            paddingBottom: "12px",
            borderRadius: "26px",
            background:
              "url(/images/mock-iphone.svg) center / contain no-repeat scroll",
          }}
        >
          <Box
            tag={"div"}
            className={"hide-scrollbar"}
            css={{
              padding: "0px",
              height: "100%",
              overflow: "hidden",
              overflowY: "auto",
              borderRadius: "18px",
            }}
            {...body}
          />
        </Box>
      </Box>
      <Box tag={"div"} />
    </Box>
  );
};

export default PreviewPost;
// #endregion
