/** Build with repleek */
// #region Import
import React from "react";

import {
  Avatar,
  AvatarProps,
  Box,
  Button,
  ButtonIcon,
  ButtonIconProps,
  ButtonProps,
  Chip,
  ChipProps,
  Container,
  ContainerProps,
  Divider,
  Icon,
  IconProps,
  Paper,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { HiOutlineCog6Tooth } from "react-icons/hi2";
import { TfiTrash } from "react-icons/tfi";
import { IoRestaurantOutline } from "react-icons/io5";
// #endregion

// #region Type Definition
type AccoutItemProps = Partial<ContainerProps> & {
  logo: Partial<AvatarProps>,
  editBtn: Partial<ButtonIconProps>,
  deleteBtn: Partial<ButtonIconProps>,
  name: TypographyProps & {},
  viewBtn: ButtonProps & {},
  email: TypographyProps & {},
  type: Partial<ChipProps>,
  typeIcon: Partial<IconProps>,
};
// #endregion

// #region AccoutItemComponent
const AccoutItem: React.FC<AccoutItemProps> = (props) => {
  const {
    logo,
    editBtn,
    deleteBtn,
    name,
    viewBtn,
    email,
    type,
    typeIcon,
    ...rootProps
  } = props;

  return (
    <Container
      maxWidth={"md"}
      disableGutters={false}
      fixed={false}
      css={{ gap: "12px", marginBottom: "22px" }}
      {...rootProps}
    >
      <Paper
        square={true}
        variant={"elevation"}
        elevation={0}
        css={{
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
          gap: "16px",
          borderRadius: "18px",
          paddingLeft: "26px",
          paddingRight: "16px",
          paddingTop: "16px",
          paddingBottom: "16px",
          display: "flex",
          alignItems: "center",
          ".mui-dark &": { backgroundColor: "rgba(39, 37, 45, 1)" },
        }}
      >
        <Avatar
          variant={"rounded"}
          component={"div"}
          css={{ width: "72px", height: "72px" }}
          {...logo}
        />
        <Box tag={"div"} css={{ flexGrow: "1", paddingLeft: "8px" }}>
          <Typography
            variant={"h4"}
            text={"Customer 1"}
            gutterBottom={false}
            noWrap={false}
            css={{ maxWidth: "400px", fontSize: "20px" }}
            {...name}
          />
          <Typography
            variant={"caption"}
            text={"Customer 1"}
            gutterBottom={false}
            noWrap={false}
            css={{ fontSize: "18px", color: "#5D5D5E" }}
            {...email}
          />
          <Divider
            chip={false}
            flexItem={false}
            light={false}
            orientation={"horizontal"}
            textAlign={"center"}
            variant={"fullWidth"}
          />
          <Chip
            label={"Chip label"}
            avatar={false}
            icon={true}
            clickable={true}
            color={"secondary"}
            variant={"filled"}
            css={{ textTransform: "uppercase", paddingLeft: "4px" }}
            Icon={
              <Icon
                color={"inherit"}
                fontSize={"inherit"}
                icon={IoRestaurantOutline}
                className={
                  "MuiChip-icon MuiChip-iconMedium MuiChip-iconColorSecondary"
                }
                css={{ fontSize: "16px" }}
                {...typeIcon}
              />
            }
            {...type}
          />
        </Box>
        <Button
          text={"Consulter"}
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          variant={"contained"}
          iconStart={false}
          iconEnd={false}
          css={{
            paddingLeft: "8px",
            paddingRight: "8px",
            paddingTop: "4px",
            paddingBottom: "4px",
          }}
          {...viewBtn}
        />
        <ButtonIcon
          type={"button"}
          disabled={false}
          disableFocusRipple={false}
          disableRipple={false}
          size={"medium"}
          color={"secondary"}
          Icon={<Icon color={"inherit"} fontSize={"inherit"} icon={TfiTrash} />}
          {...deleteBtn}
        />
        <ButtonIcon
          type={"button"}
          disabled={false}
          disableFocusRipple={false}
          disableRipple={false}
          size={"medium"}
          color={"primary"}
          Icon={
            <Icon
              color={"inherit"}
              fontSize={"inherit"}
              icon={HiOutlineCog6Tooth}
            />
          }
          {...editBtn}
        />
      </Paper>
    </Container>
  );
};

export default AccoutItem;
// #endregion
