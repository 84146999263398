import React, { useCallback } from "react";
import LoginForm from "./LoginForm";
import { useStrapi } from "@/providers/StrapiProvider";
import { LoginLayout } from "@/components";

const LoginPage: React.FC = () => {
  const [strapi] = useStrapi();

  const handleLogin = useCallback(
    async (values: { email: string; password: string }) => {
      await strapi?.login({
        identifier: values.email,
        password: values.password,
      });
    },
    [strapi]
  );

  return (
    <LoginLayout
      page={{ children: <LoginForm form={{ onSubmit: handleLogin }} /> }}
    />
  );
};

export default LoginPage;
