import { strapiInstance } from "@/providers/StrapiProvider";
import { selector, selectorFamily } from "recoil";

export const notionUnlinkedAccounts = selector({
  key: "notion-unlinked-account",
  get: async () => {
    return await strapiInstance.get("notion/accounts");
  },
});

const notionOptions = selector({
  key: "notion-options-selector",
  get: async () => {
    const res = await strapiInstance.get(
      "notion",
      {},
      {
        populate: "options",
      }
    );
    return res?.data.options || [];
  },
});

export const notionOptionByValue = selectorFamily({
  key: "notion-option-by-value",
  get:
    ({
      collection,
      key,
    }: {
      collection: "clients" | "livrables";
      key: string | undefined;
    }) =>
    ({ get }) => {
      if (!key) return undefined;
      const options = get(notionOptions);

      return options.find(
        ({ value, table }) => table === collection && value === key
      );
    },
});
