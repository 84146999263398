/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  Button,
  Chip,
  Container,
  Divider,
  DividerProps,
  Form,
  FormProps,
  Fragment,
  FragmentProps,
  Paper,
  TextField,
  Typography,
  TypographyProps,
} from "@repleek/mui";
// #endregion

// #region Type Definition
type RepportingFormProps = Partial<FormProps> & {
  form: Partial<FormProps>,
  title: TypographyProps & {},
  facebookTitle: Partial<DividerProps>,
  tiktokTitle: Partial<DividerProps>,
  instagramTitle: Partial<DividerProps>,
  linkedinTitle: Partial<DividerProps>,
  facebook: Partial<BoxProps>,
  instagram: Partial<BoxProps>,
  tiktok: Partial<BoxProps>,
  linkedin: Partial<BoxProps>,
  date: Partial<FragmentProps>,
};
// #endregion

// #region RepportingFormComponent
const RepportingForm: React.FC<RepportingFormProps> = (props) => {
  const {
    form,
    title,
    facebookTitle,
    tiktokTitle,
    instagramTitle,
    linkedinTitle,
    facebook,
    instagram,
    tiktok,
    linkedin,
    date,
    ...rootProps
  } = props;

  return (
    <Form mode={"onSubmit"} {...form} {...rootProps}>
      <Container maxWidth={"md"} disableGutters={false} fixed={false}>
        <Paper
          square={false}
          variant={"outlined"}
          elevation={0}
          css={{
            maxHeight: "80vh",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "#000000",
            boxShadow: "rgba(240, 46, 170, 0.4) 5px 5px",
            overflowY: "auto",
            borderRadius: "26px",
            paddingLeft: "42px",
            paddingRight: "42px",
            paddingTop: "42px",
          }}
        >
          <Typography
            variant={"h1"}
            text={"Nouveau reporting"}
            gutterBottom={false}
            noWrap={false}
            css={{
              fontSize: "28px",
              marginBottom: "26px",
              textTransform: "uppercase",
            }}
            {...title}
          />
          <Box
            tag={"div"}
            css={{
              paddingLeft: "16px",
              paddingRight: "16px",
              paddingTop: "8px",
              paddingBottom: "8px",
            }}
          >
            <Box
              tag={"div"}
              css={{
                gap: "16px",
                marginBottom: "16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                inputName={"name"}
                label={"Nom du reporting"}
                variant={"outlined"}
                color={"primary"}
                type={"text"}
                aria-label={"Input Base Mui"}
                autoFocus={false}
                disabled={false}
                fullWidth={false}
                maxRows={1}
                minRows={1}
                rows={1}
                multiline={false}
                readOnly={false}
                required={true}
                size={"medium"}
                css={{ flexGrow: "1" }}
              />
              <Fragment {...date} />
            </Box>
            <TextField
              inputName={"comment"}
              label={"Commentaire général"}
              variant={"outlined"}
              color={"primary"}
              type={"text"}
              aria-label={"Input Base Mui"}
              autoFocus={false}
              disabled={false}
              fullWidth={true}
              maxRows={"6"}
              minRows={1}
              rows={"2"}
              multiline={true}
              readOnly={false}
              required={false}
              size={"medium"}
            />
          </Box>
          <Divider
            label={"Facebook Reporting"}
            chip={false}
            flexItem={false}
            light={false}
            orientation={"horizontal"}
            textAlign={"left"}
            variant={"fullWidth"}
            css={{
              fontFamily: "'Unbounded',cursive",
              fontSize: "18px",
              fontWeight: 700,
              textTransform: "uppercase",
            }}
            Chip={
              <Chip
                label={"Test"}
                avatar={false}
                icon={false}
                clickable={false}
                color={"default"}
                variant={"filled"}
              />
            }
            {...facebookTitle}
          />
          <Box tag={"div"} css={{ padding: "16px" }} {...facebook} />
          <Divider
            label={"Instagram Reporting"}
            chip={false}
            flexItem={false}
            light={false}
            orientation={"horizontal"}
            textAlign={"left"}
            variant={"fullWidth"}
            css={{
              fontFamily: "'Unbounded',cursive",
              fontSize: "18px",
              fontWeight: 700,
              textTransform: "uppercase",
            }}
            {...instagramTitle}
          />
          <Box tag={"div"} css={{ padding: "16px" }} {...instagram} />
          <Divider
            label={"Tiktok Reporting"}
            chip={false}
            flexItem={false}
            light={false}
            orientation={"horizontal"}
            textAlign={"left"}
            variant={"fullWidth"}
            css={{
              fontFamily: "'Unbounded',cursive",
              fontSize: "18px",
              fontWeight: 700,
              textTransform: "uppercase",
            }}
            {...tiktokTitle}
          />
          <Box tag={"div"} css={{ padding: "16px" }} {...tiktok} />
          <Divider
            label={"LinkedIn Reporting"}
            chip={false}
            flexItem={false}
            light={false}
            orientation={"horizontal"}
            textAlign={"left"}
            variant={"fullWidth"}
            css={{
              fontFamily: "'Unbounded',cursive",
              fontSize: "18px",
              fontWeight: 700,
              textTransform: "uppercase",
            }}
            {...linkedinTitle}
          />
          <Box tag={"div"} css={{ padding: "16px" }} {...linkedin} />
          <Box
            tag={"div"}
            css={{
              backgroundColor: "rgba(255,255,255, 1)",
              zIndex: "100",
              textAlign: "right",
              position: "sticky",
              bottom: "0px",
              paddingTop: "16px",
              paddingBottom: "26px",
              ".mui-dark &": { backgroundColor: "rgba(39, 37, 45, 1)" },
            }}
          >
            <Button
              text={"Enregistrer"}
              type={"submit"}
              disabled={false}
              disableElevation={false}
              disableFocusRipple={false}
              disableRipple={false}
              fullWidth={false}
              variant={"contained"}
              iconStart={false}
              iconEnd={false}
            />
          </Box>
        </Paper>
      </Container>
    </Form>
  );
};

export default RepportingForm;
// #endregion
