import { Box, Typography } from "@mui/material";
import React, { useMemo } from "react";
import {
  LineChart,
  Line,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
} from "recharts";

type Props = {
  title: string;
  label: string;
  color: string;
  prefix?: string;
  data: {
    value: number;
    name: string;
    subValues: { name: string; value: number }[];
  }[];
  diff?: boolean;
};

type CustomTooltipProps = { unit: string; color: string } & TooltipProps<
  any,
  any
>;

const LineChartView: React.FC<Props> = (props) => {
  const { title, label, color, data, diff, prefix } = props;

  const insight = useMemo(() => {
    if (data?.length) {
      const first = data[0];
      const last = data[data.length - 1];
      const value = new Intl.NumberFormat("fr-FR").format(last.value);

      return {
        ...last,
        value,
        diff: last.value - first.value,
        label: last.subValues
          .map(
            ({ name, value }) =>
              `${name} ${new Intl.NumberFormat("fr-FR").format(value)}`
          )
          .join(" - "),
      };
    }
    return { value: 0, diff: 0, label: "" };
  }, [data]);

  return (
    <Box
      sx={{ border: "1px solid", borderRadius: "16px", padding: "16px 8px" }}>
      <Typography
        variant="h3"
        sx={{
          fontSize: "20px",
          textAlign: "center",
          textTransform: "uppercase",
          fontWeight: "500",
          mb: "8px",
        }}>
        {title}
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mb: "12px",
          gap: "14px",
        }}>
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: "600",
              color,
              mb: 1,
              lineHeight: "36px",
            }}>
            {insight?.value} {prefix && <span>{prefix}</span>}
          </Typography>
          <Typography>{label || insight.label}</Typography>
        </Box>

        {diff && (
          <Box sx={{ borderLeft: "1px solid", paddingLeft: "8px" }}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "600",
                mb: 0,
                lineHeight: "36px",
                fontSize: "18px",
              }}>
              + {insight.diff}
            </Typography>
          </Box>
        )}
      </Box>

      <div
        className="bg-white"
        style={{
          height: "110px",
          width: "",
        }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}>
            <Tooltip content={<CustomTooltip unit={label} color={color} />} />
            <Line
              type="monotone"
              dataKey="value"
              stroke={color}
              activeDot={{ r: 6 }}
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Box>
  );
};

export default LineChartView;

const CustomTooltip: React.FC<CustomTooltipProps> = (props) => {
  const { active, payload, unit, color } = props;

  const label = useMemo(
    () =>
      payload?.[0]?.payload?.["subValues"]
        .map(({ name, value }: any) => `${name} ${value}`)
        .join(" - ") || "",
    [payload]
  );

  if (active && payload && payload.length) {
    return (
      <Box
        className="bg-white"
        sx={{
          border: "1px solid",
          borderRadius: "8px",
          padding: "8px",
          textAlign: "center",
        }}>
        <Typography fontWeight="600" textTransform="uppercase">
          {payload[0].payload.name}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            color,
            mb: 0,
            lineHeight: "28px",
            fontSize: "24px",
          }}>
          {payload[0].value}
        </Typography>
        <Typography>{unit || label}</Typography>
      </Box>
    );
  }

  return null;
};
