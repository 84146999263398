import React, { useCallback } from "react";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { useFetchPaginable } from "@/hooks/fetch";

type Props = {
  id?: number;
  type?: string;
};

const columns: GridColDef[] = [
  {
    field: "date",
    headerName: "Date",
    width: 130,
    sortable: true,
    disableColumnMenu: true,
  },
  {
    field: "statistics.followers",
    headerName: "Followers",
    width: 110,
    sortable: false,
    disableColumnMenu: true,
    type: "number",
    valueGetter: ({ row }) => row?.statistics?.followers,
  },
  {
    field: "impressions",
    headerName: "Impressions",
    width: 110,
    type: "number",
    sortable: false,
    disableColumnMenu: true,
    valueGetter: ({ row }) => row?.statistics?.impressions,
  },
  {
    field: "interactions",
    headerName: "Interactions",
    type: "number",
    width: 110,
    sortable: false,
    disableColumnMenu: true,
    valueGetter: ({ row }) => row?.statistics?.interactions,
  },
  {
    field: "posts",
    headerName: "Posts",
    type: "number",
    width: 110,
    sortable: false,
    disableColumnMenu: true,
    valueGetter: ({ row }) => row?.statistics?.posts,
  },
  {
    field: "engagement",
    headerName: "Engagement",
    type: "number",
    width: 110,
    sortable: false,
    disableColumnMenu: true,
    valueGetter: ({ row }) => `${row?.statistics?.engagement} %`,
  },
];

const SocialStatisticTable: React.FC<Props> = (props) => {
  const { id, type } = props;
  const [{ data = [], loading, page = 0, sort, count }, { setSort, setPage }] =
    useFetchPaginable("social-statistics", {
      populate: ["statistics"],
      filters: {
        account: {
          id: {
            $eq: id,
          },
        },
        type,
      },
    });

  const onSortModelChange = useCallback(
    (model: GridSortModel) => {
      setSort?.(model);
    },
    [setSort]
  );
  const onPaginationModelChange = useCallback(
    (model: GridPaginationModel) => {
      setPage?.(model.page);
    },
    [setPage]
  );

  return (
    <Box sx={{ height: 476, width: "100%", userSelect: "none", mt: 2 }}>
      <DataGrid
        rows={data}
        columns={columns}
        rowSelection={false}
        onSortModelChange={onSortModelChange}
        onPaginationModelChange={onPaginationModelChange}
        paginationModel={{ page, pageSize: 7 }}
        sortModel={sort}
        rowCount={count}
        paginationMode="server"
        loading={loading}
      />
    </Box>
  );
};

export default SocialStatisticTable;
