/** Build with repleek */
// #region Import
import React from "react";

import {
  Autocomplete,
  AutocompleteProps,
  Container,
  ContainerProps,
} from "@repleek/mui";
// #endregion

// #region Type Definition
type AccountFilterFormProps = Partial<ContainerProps> & {
  type: Partial<AutocompleteProps>,
};
// #endregion

// #region AccountFilterFormComponent
const AccountFilterForm: React.FC<AccountFilterFormProps> = (props) => {
  const { type, ...rootProps } = props;

  return (
    <Container
      maxWidth={"xs"}
      disableGutters={false}
      fixed={false}
      css={{ paddingTop: "16px", paddingBottom: "8px" }}
      {...rootProps}
    >
      <Autocomplete
        inputName={"type"}
        label={"Type de compte"}
        variant={"outlined"}
        color={"secondary"}
        aria-label={"Input Base Mui"}
        clearText={"Vider"}
        closeText={"Fermer"}
        loadingText={"Chargement ..."}
        noOptionsText={"No option"}
        disabled={false}
        required={false}
        autoComplete={false}
        autoHighlight={false}
        autoSelect={false}
        blurOnSelect={false}
        clearOnBlur={false}
        clearOnEscape={false}
        fullWidth={true}
        disableClearable={false}
        disableCloseOnSelect={false}
        disabledItemsFocusable={false}
        disableListWrap={false}
        disablePortal={false}
        filterSelectedOptions={true}
        freeSolo={false}
        multiple={true}
        chipsColor={"default"}
        chipsSize={"medium"}
        chipsVariant={"filled"}
        css={{ minWidth: "340px" }}
        {...type}
      />
    </Container>
  );
};

export default AccountFilterForm;
// #endregion
