import React from "react";
import { Box, Typography } from "@mui/material";

type Props = {
  title: string;
  comment: string;
};

const Comment: React.FC<Props> = (props) => {
  const { title, comment } = props;

  return (
    <Box
      sx={{
        border: "1px solid",
        borderRadius: "16px",
        padding: "16px 8px",
        textAlign: "center",
      }}>
      <Typography
        variant="h3"
        sx={{
          fontSize: "20px",
          textTransform: "uppercase",
          fontWeight: "500",
          mb: "8px",
        }}>
        {title}
      </Typography>

      <Typography>{comment}</Typography>
    </Box>
  );
};

export default Comment;
