import React from "react";
import { Breadcrumbs, Chip } from "@mui/material";
import { useNavigate } from "@repleek/mui";
import { useRecoilValue } from "recoil";
import { layoutState } from "@/store/layout";
import { FaCaretRight } from "react-icons/fa6";

const BreadCrumb: React.FC = () => {
  const { navigate } = useNavigate();
  const { breadcrumbs } = useRecoilValue(layoutState);

  return (
    <Breadcrumbs aria-label="breadcrumb" separator={<FaCaretRight />}>
      {breadcrumbs?.map(
        ({ label, icon: Icon, href }) =>
          label &&
          (href ? (
            <Chip
              key={label}
              icon={Icon && <Icon fontSize="small" />}
              label={label}
              onClick={() => navigate?.(href)}
              sx={{ pl: 0.5, textTransform: "uppercase" }}
            />
          ) : (
            <Chip
              key={label}
              icon={Icon && <Icon fontSize="small" />}
              label={label}
              variant="outlined"
              sx={{ pl: 1, textTransform: "uppercase" }}
            />
          ))
      )}
    </Breadcrumbs>
  );
};

export default BreadCrumb;
