import React, { useCallback, useMemo, useState } from "react";
import "./style.css";
import clsx from "clsx";
import { Box } from "@mui/material";

export type Tab = {
  id: string;
  label: React.ReactNode;
  content: React.ReactNode;
};
type Props = {
  tabs: Tab[];
  defaultTab: string;
};

const ReportingTab: React.FC<Props> = (props) => {
  const { tabs, defaultTab } = props;
  const [active, setActive] = useState<string | null>(defaultTab);

  const content = useMemo(() => {
    const tab = tabs.find(({ id }) => id === active);
    if (tab) return tab.content;
    return <></>;
  }, [active, tabs]);

  const onChangeTab = useCallback((id: string) => {
    setActive(id);
  }, []);

  return (
    <Box className="reporting-tab" sx={{ mt: "22px", flexGrow: 1 }}>
      <div className="tab-nav">
        {tabs.map(({ id, label }) => (
          <div
            className={clsx({ nav: true, active: id === active })}
            key={id}
            onClick={() => onChangeTab(id)}>
            {label}
          </div>
        ))}
      </div>
      <div className="tab-body">{content}</div>
    </Box>
  );
};

export default ReportingTab;
