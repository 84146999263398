/** Generated automaticaly by dbuilder */
import es from "./es.json";
import fr from "./fr.json";
import gb from "./gb.json";

const resources = {
  es: { translation: es },
  fr: { translation: fr },
  gb: { translation: gb },
};
export default resources;
