/** Build with repleek */
// #region Import
import React from "react";

import { Box, BoxProps, Container, Image, Typography } from "@repleek/mui";
// #endregion

// #region Type Definition
type UnderContructionProps = Partial<BoxProps> & {};
// #endregion

// #region UnderContructionComponent
const UnderContruction: React.FC<UnderContructionProps> = (props) => {
  const { ...rootProps } = props;

  return (
    <Box
      tag={"div"}
      css={{
        height: "calc(100vh - 100px)",
        display: "flex",
        alignItems: "center",
      }}
      {...rootProps}
    >
      <Container
        maxWidth={"lg"}
        disableGutters={false}
        fixed={false}
        css={{
          backgroundColor: "rgba(255,255,255, 1)",
          paddingTop: "160px",
          paddingBottom: "180px",
          borderRadius: "16px",
          position: "relative",
          ".mui-dark &": { backgroundColor: "rgba(39, 37, 45, 1)" },
        }}
      >
        <Box tag={"div"} css={{ paddingLeft: "48px" }}>
          <Typography
            variant={"h1"}
            text={"Under<br />Construction"}
            gutterBottom={false}
            noWrap={false}
            css={{ fontSize: "64px", lineHeight: "64px", marginBottom: "8px" }}
          />
          <Typography
            variant={"body1"}
            text={"Magic is going to happen 😜"}
            gutterBottom={false}
            noWrap={false}
            css={{ fontSize: "22px", color: "rgb(112, 112, 122)" }}
          />
        </Box>
        <Image
          alt={"Image description for better SEO"}
          src={"/images/under-construction.svg"}
          dynamic={true}
          css={{
            width: "480px",
            position: "absolute",
            bottom: "16px",
            right: "16px",
          }}
        />
      </Container>
    </Box>
  );
};

export default UnderContruction;
// #endregion
