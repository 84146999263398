export function isBrowser(): boolean {
  return typeof window !== "undefined";
}

export function replaceStringWithParams(
  inputString: string,
  params?: Record<string, string | number>
) {
  if (!params) return inputString;

  return Object.entries(params).reduce((acc, [key, value]) => {
    return acc.replaceAll(key, value.toString());
  }, inputString);
}
