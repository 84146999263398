/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  Button,
  ButtonIcon,
  ButtonProps,
  Container,
  Form,
  FormProps,
  Icon,
  InputBase,
} from "@repleek/mui";
import { FiSearch } from "react-icons/fi";
// #endregion

// #region Type Definition
type ListAccountProps = Partial<BoxProps> & {
  addBtn: ButtonProps & {},
  pagination: Partial<BoxProps>,
  accounts: Partial<BoxProps>,
  search: Partial<FormProps>,
  filterBtn: ButtonProps & {},
  filter: Partial<BoxProps>,
};
// #endregion

// #region ListAccountComponent
const ListAccount: React.FC<ListAccountProps> = (props) => {
  const {
    addBtn,
    pagination,
    accounts,
    search,
    filterBtn,
    filter,
    ...rootProps
  } = props;

  return (
    <Box tag={"div"} {...rootProps}>
      <Container maxWidth={"md"} disableGutters={false} fixed={false}>
        <Box
          tag={"div"}
          css={{
            textAlign: "right",
            paddingBottom: "32px",
            paddingLeft: "24px",
            paddingRight: "24px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Form
            mode={"onSubmit"}
            css={{
              backgroundColor: "rgba(255,255,255, 1)",
              boxShadow:
                "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
              borderRadius: "32px",
              paddingLeft: "16px",
              paddingRight: "8px",
              paddingTop: "6px",
              paddingBottom: "6px",
              display: "flex",
              ".mui-dark &": {
                backgroundColor: "rgba(255, 255, 255, 0.11)",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "rgba(251, 251, 251, 0.34)",
              },
            }}
            {...search}
          >
            <InputBase
              inputName={"search"}
              color={"primary"}
              type={"text"}
              aria-label={"Input Base Mui"}
              placeholder={"Recherche par nom ou email ..."}
              autoFocus={false}
              disabled={false}
              fullWidth={false}
              maxRows={1}
              minRows={1}
              rows={1}
              multiline={false}
              readOnly={false}
              required={false}
              size={"medium"}
              css={{ width: "270px", fontSize: "18px" }}
            />
            <ButtonIcon
              type={"submit"}
              disabled={false}
              disableFocusRipple={false}
              disableRipple={false}
              css={{
                backgroundColor: "rgba(0,255,0, 1)",
                fontSize: "16px",
                color: "#000",
                ":hover": { backgroundColor: "rgba(0,255,0, 1)" },
              }}
              Icon={
                <Icon color={"inherit"} fontSize={"inherit"} icon={FiSearch} />
              }
            />
          </Form>
          <Box tag={"div"}>
            <Button
              text={"Filtrer"}
              type={"button"}
              disabled={false}
              disableElevation={false}
              disableFocusRipple={false}
              disableRipple={false}
              fullWidth={false}
              size={"small"}
              variant={"outlined"}
              color={"secondary"}
              iconStart={false}
              iconEnd={false}
              css={{
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "6px",
                paddingBottom: "6px",
                marginRight: "12px",
              }}
              {...filterBtn}
            />
            <Button
              text={"Nouveau"}
              type={"button"}
              disabled={false}
              disableElevation={false}
              disableFocusRipple={false}
              disableRipple={false}
              fullWidth={false}
              size={"small"}
              variant={"contained"}
              color={"secondary"}
              iconStart={false}
              iconEnd={false}
              href={"/new-account"}
              css={{
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "6px",
                paddingBottom: "6px",
              }}
              {...addBtn}
            />
          </Box>
        </Box>
        <Box tag={"div"} css={{ paddingLeft: "16px" }} {...filter} />
        <Box tag={"div"} css={{ minHeight: "60vh" }} {...accounts} />
        <Box
          tag={"div"}
          css={{ marginTop: "21px", display: "flex", justifyContent: "center" }}
          {...pagination}
        />
      </Container>
    </Box>
  );
};

export default ListAccount;
// #endregion
