import { ContentType } from "@/modules/strapi-sdk/lib/api";
import { useStrapi } from "@/providers/StrapiProvider";
import { notificationsSelector } from "@/store/notification";
import { Avatar, Box, Button, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { MouseEventHandler, useCallback, useMemo } from "react";
import { useRecoilRefresher_UNSTABLE } from "recoil";

type Props = {
  notifocation: ContentType["notifications"];
  userId?: number;
  onClick: (notification: ContentType["notifications"]) => void;
};

const Notification: React.FC<Props> = (props) => {
  const { notifocation, userId, onClick } = props;
  const [strapi] = useStrapi();
  const refreshNotifications = useRecoilRefresher_UNSTABLE(
    notificationsSelector
  );

  const opened = useMemo(
    () => notifocation?.read_by?.includes(userId),
    [notifocation?.read_by, userId]
  );

  const creator = useMemo(
    () => ({
      initial: [
        notifocation.creator?.firstName?.[0],
        notifocation.creator?.lastName?.[0],
      ]
        .filter((v) => v)
        .join(""),
      fullName: [
        notifocation.creator?.firstName,
        notifocation.creator?.lastName,
      ]
        .filter((v) => v)
        .join(" "),
    }),
    [notifocation.creator]
  );

  const caption = useMemo(
    () =>
      [creator.fullName, dayjs(notifocation.createdAt).fromNow()]
        .filter((v) => v)
        .join(" - "),
    [creator.fullName, notifocation.createdAt]
  );

  const onArchive: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.stopPropagation();
      if (notifocation.id)
        strapi
          ?.update("notifications", notifocation.id, {
            archived: true,
          })
          .then(refreshNotifications);
    },
    [notifocation.id, refreshNotifications, strapi]
  );

  return (
    <Box
      sx={{
        px: 2,
        ":hover": {
          background: "#FFF2FA",
        },
        background: opened ? "white" : "#FEF7F1",
      }}
      onClick={() => onClick(notifocation)}>
      <Box
        sx={{
          borderBottom: "1px solid #cdcad5",
          py: 1,
          display: "flex",
          color: "#58585b",
          cursor: "pointer",
        }}>
        <Avatar
          sx={{ background: creator.initial && "#FF4DF7" }}
          src={!creator.initial ? "/images/logo-mini.png" : ""}>
          {creator.initial}
        </Avatar>
        <Box sx={{ pl: 2 }}>
          <Typography
            variant="h4"
            sx={{ fontSize: "16px", mb: -0.6, color: "#373638" }}>
            {notifocation.title}
          </Typography>

          <Typography
            variant="caption"
            sx={{ textTransform: "uppercase", mb: 2.5 }}>
            {caption}
          </Typography>

          <p
            dangerouslySetInnerHTML={{
              __html: notifocation.message || "",
            }}
            style={{ margin: 0, lineHeight: "18px", fontSize: "14px" }}></p>

          {!notifocation.archived && (
            <Button
              size="small"
              color="secondary"
              variant="contained"
              sx={{ px: 1, py: 0.3, my: 0.8, fontSize: "12px" }}
              onClick={onArchive}>
              Archiver
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Notification;
