import React, { useCallback, useMemo, useState } from "react";
import NewReportModal from "./NewReportModal";
import FilePicker from "@/components/fields/file-picker";
import { SocialStatistic } from "@/modules/strapi-sdk/lib/interfaces";
import { useStrapi } from "@/providers/StrapiProvider";
import { Modal, PistacheLoader } from "@/components/layout";
import { chunkArray } from "@/utils";
import { toast } from "react-toastify";

type Props = {
  id: string;
};

type HootsuiteInsights = {
  name: string;
  insights: Omit<SocialStatistic, "id">[];
};

const ImportInsights: React.FC<Props> = ({ id }) => {
  const [strapi, { loading }] = useStrapi();
  const [insights, setInsights] = useState<HootsuiteInsights | undefined>();
  const [progress, setProgress] = useState<number | "BUFFERING" | undefined>(
    undefined
  );

  const importConfirmation = useMemo(() => {
    if (insights?.name && insights.insights.length) {
      return {
        name: insights.name,
        range: {
          from: insights.insights[0]?.date,
          to: insights.insights[insights.insights.length - 1]?.date,
        },
      };
    }

    return undefined;
  }, [insights]);

  const onHootsuiteImportChange = useCallback(
    async (file: File) => {
      // upload zip archive
      const formData = new FormData();
      formData.append("file", file);
      const res = await strapi?.request<HootsuiteInsights | undefined>(
        "POST",
        "importer/hootsuite",
        {
          data: formData,
        }
      );

      setInsights(res);
    },
    [strapi]
  );

  const onUpsertInsights = useCallback(async () => {
    setInsights(undefined);
    setProgress("BUFFERING");

    if (insights?.insights) {
      let progress = 0;

      const batches = chunkArray(insights.insights, 100);
      for await (const batch of batches) {
        await strapi?.post(
          "/social-statistic/upsert/:accountId",
          { ":accountId": id },
          batch
        );
        progress = progress + (batch.length / insights.insights.length) * 100;
        setProgress(progress);
      }

      setProgress(undefined);
      toast("Les données ont été importées avec succès !");
    }
  }, [insights?.insights, strapi, id]);

  return (
    <>
      <NewReportModal
        fileUploader={{
          children:
            progress === undefined ? (
              <FilePicker
                disabled={loading}
                accept={{
                  "application/zip": [".zip"],
                }}
                multiple={false}
                onChange={onHootsuiteImportChange}
              />
            ) : (
              <PistacheLoader
                {...(typeof progress === "number" && {
                  variant: "determinate",
                  value: progress,
                })}
              />
            ),
        }}
      />

      <Modal
        type="box"
        open={!!importConfirmation}
        title="Import HootSuite"
        message={`Attention, vous êtes sur le point d'importer le rapport du compte <b>${importConfirmation?.name}</b> couvrant la période du <b>${importConfirmation?.range.from}</b> au <b>${importConfirmation?.range.to}</b>. Cette action est définitive et ne peut être annulée.`}
        onClose={() => setInsights(undefined)}
        onConfirm={onUpsertInsights}
      />
    </>
  );
};

export default ImportInsights;
