import React, { Suspense, useCallback, useState } from "react";
import { AccountDetail, NotFound } from "@/components";
import Layout, { PistacheLoader } from "@/components/layout";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { accountSelector } from "@/store/accounts";
import { EmailModal } from "@/components/features/notificationCenter";
import { BusinessCenter, Home } from "@mui/icons-material";
import { useBreadCrumb } from "@/hooks/layout";
import { toast } from "react-toastify";
import Insight from "./insight";
import ReportingPage from "./reporting";
import Planning from "./planning";

const Account: React.FC = () => {
  const { id, page } = useParams() as any;

  if (!id) return <NotFound />;

  return (
    <Layout title="Pistache - nouveau compte" mode="ADMIN">
      <Suspense fallback={<PistacheLoader />}>
        <Details id={id} page={page} />
      </Suspense>
    </Layout>
  );
};

const Details: React.FC<{ id: string; page: string }> = ({ id, page }) => {
  const account = useRecoilValue(accountSelector(id));
  const [notification, setNotification] = useState(false);

  useBreadCrumb([
    { label: "Comptes", icon: Home, href: "/accounts" },
    { label: account?.name, icon: BusinessCenter },
  ]);

  const copyToClipboard = useCallback((text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast("Text copied to clipboard", { type: "success" });
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        toast("Failed to copy text", { type: "error" });
      });
  }, []);

  const openNotionPage = useCallback(() => {
    if (account?.notion_url) window.open(account.notion_url, "_blank");
  }, [account?.notion_url]);

  if (!account?.id) return <NotFound />;

  return (
    <>
      <AccountDetail
        editBtn={{
          href: `/edit-account/${id}`,
        }}
        logo={{
          src: account?.url || "/images/logo-pistache.jpg",
        }}
        name={{ text: account.name }}
        email={{ text: account.email }}
        notification={{ onClick: () => setNotification(true) }}
        statisticTab={{
          variant: !page ? "contained" : "text",
          href: `/account/${id}`,
        }}
        reportingTab={{
          variant: page === "reporting" ? "contained" : "text",
          href: `/account/${id}/reporting`,
        }}
        planningTab={{
          variant: page === "planning" ? "contained" : "text",
          href: `/account/${id}/planning`,
        }}
        hootsuitId={{
          text: `RAPPORT PIST'APP ${account.name.toUpperCase()} ID:${
            account.id
          }`,
        }}
        copy={{
          onClick: () =>
            copyToClipboard(
              `RAPPORT PIST'APP ${account.name.toUpperCase()} ID:${account.id}`
            ),
        }}
        notionUrl={{
          hidden: !account.notion_url,
          onClick: openNotionPage,
        }}
        hootsuite={{
          hidden: !!page,
        }}
      />
      {!page && <Insight id={id} />}

      {page === "reporting" && <ReportingPage id={id} />}

      {page === "planning" && (
        <Suspense
          fallback={<PistacheLoader css={{ height: "calc(100vh - 110px)" }} />}>
          <Planning id={id} />
        </Suspense>
      )}

      <EmailModal
        open={notification}
        content={{
          resetPassword: {
            link: `https://dashboard.agence-pistache.fr/reset-password?token=${account?.resetToken}`,
          },
          welcomeEmail: {
            link: `https://dashboard.agence-pistache.fr/reset-password?token=${account?.resetToken}&name=${account?.name}`,
          },
        }}
        email={account.email}
        onClose={() => setNotification(false)}
      />
    </>
  );
};

export default Account;
