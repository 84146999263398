/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  Container,
  ContainerProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";
// #endregion

// #region Type Definition
type ReportingSelectProps = Partial<ContainerProps> & {
  name: TypographyProps & {},
  select: Partial<BoxProps>,
};
// #endregion

// #region ReportingSelectComponent
const ReportingSelect: React.FC<ReportingSelectProps> = (props) => {
  const { name, select, ...rootProps } = props;

  return (
    <Container
      maxWidth={"md"}
      disableGutters={false}
      fixed={false}
      className={"bg-white"}
      css={{
        padding: "32px",
        boxShadow:
          "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        gap: "8px",
        marginTop: "38px",
        marginBottom: "38px",
        borderRadius: "16px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "@media (max-width: 700px)": { padding: "14px" },
      }}
      {...rootProps}
    >
      <Typography
        variant={"h1"}
        text={"Mon titre ici"}
        gutterBottom={false}
        noWrap={false}
        css={{
          fontSize: "24px",
          "@media (max-width: 700px)": { fontSize: "18px" },
        }}
        {...name}
      />
      <Box tag={"div"} {...select} />
    </Container>
  );
};

export default ReportingSelect;
// #endregion
