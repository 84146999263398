import Comment from "@/components/fields/charts/Comment";
import LineChartView from "@/components/fields/charts/Line";
import { PistacheLoader } from "@/components/layout";
import { insightsByType } from "@/store/reporting";
import { Box } from "@mui/material";
import React, { Suspense } from "react";
import { useRecoilValue } from "recoil";

export type ReportingViewType =
  | "global"
  | "linkedin"
  | "tiktok"
  | "facebook"
  | "instagram";

type Props = {
  type: ReportingViewType;
  accountId: string;
};

const ReportingView: React.FC<Props> = (props) => {
  const data = useRecoilValue(insightsByType(props));

  if (!data) return null;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: "16px",
        padding: "16px",
        userSelect: "none",
        "@media screen and (max-width: 800px)": {
          gridTemplateColumns: "repeat(2, 1fr)",
        },
        "@media screen and (max-width: 640px)": {
          gridTemplateColumns: "repeat(1, 1fr)",
        },
      }}>
      <Suspense fallback={<PistacheLoader />}>
        <LineChartView
          data={data["insights"]["impressions"]}
          title={"Couverture"}
          label={"Personnes touchées"}
          color={"#00FD7A"}
        />
        <LineChartView
          title={"Interactions"}
          label={""}
          color={"#5900FF"}
          data={data["insights"]["interactions"]}
        />
        <LineChartView
          title={"Contenus"}
          label={"Publications"}
          color={"#5900FF"}
          data={data["insights"]["posts"]}
        />
        <LineChartView
          title={"Engagement"}
          label={""}
          color={"#FF4DF7"}
          data={data["insights"]["engagement"]}
          prefix="%"
        />

        <LineChartView
          title={"Followers"}
          label={"Abonnées"}
          color={"#FF4DF7"}
          data={data["insights"]["followers"]}
          diff
        />

        {data["comment"] && (
          <Comment title={"Observation"} comment={data["comment"]} />
        )}
      </Suspense>
    </Box>
  );
};

export default ReportingView;
