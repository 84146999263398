/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Image,
  Typography,
  TypographyProps,
} from "@repleek/mui";
// #endregion

// #region Type Definition
type EmailResumeProps = Partial<ContainerProps> & {
  edit: ButtonProps & {},
  validate: ButtonProps & {},
  email: TypographyProps & {
    email?: string,
  },
  subject: TypographyProps & {
    subject?: string,
  },
  headLine: TypographyProps & {},
  message: TypographyProps & {},
  buttonName: TypographyProps & {},
};
// #endregion

// #region EmailResumeComponent
const EmailResume: React.FC<EmailResumeProps> = (props) => {
  const {
    edit,
    validate,
    email,
    subject,
    headLine,
    message,
    buttonName,
    ...rootProps
  } = props;

  return (
    <Container
      maxWidth={"sm"}
      disableGutters={false}
      fixed={false}
      {...rootProps}
    >
      <Box
        tag={"div"}
        css={{
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "#DDDBDB",
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px , rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          overflow: "hidden",
          borderRadius: "8px",
          marginBottom: "12px",
        }}
      >
        <Box tag={"div"}>
          <Typography
            variant={"body2"}
            text={"À <b>{{email}}</b>"}
            gutterBottom={false}
            noWrap={false}
            css={{
              padding: "8px",
              borderBottomWidth: "1px",
              borderBottomStyle: "solid",
              borderBottomColor: "#DDDBDB",
            }}
            {...email}
          />
          <Typography
            variant={"body2"}
            text={"Objet <b>{{subject}}</b>"}
            gutterBottom={false}
            noWrap={false}
            css={{
              padding: "8px",
              borderBottomWidth: "1px",
              borderBottomStyle: "solid",
              borderBottomColor: "#DDDBDB",
            }}
            {...subject}
          />
          <Box
            tag={"div"}
            css={{
              padding: "24px",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant={"h3"}
              text={"Mon titre ici"}
              gutterBottom={false}
              noWrap={false}
              css={{ fontSize: "18px" }}
              {...headLine}
            />
            <Typography
              variant={"body2"}
              text={"Mon titre ici"}
              gutterBottom={false}
              noWrap={false}
              css={{ fontSize: "16px", marginTop: "8px", marginBottom: "16px" }}
              {...message}
            />
            <Typography
              variant={"h1"}
              text={"Mon titre ici"}
              gutterBottom={false}
              noWrap={false}
              css={{
                backgroundColor: "rgba(0, 253, 122, 1)",
                fontSize: "14px",
                fontWeight: 500,
                borderWidth: "1px",
                borderStyle: "solid",
                marginTop: "8px",
                marginBottom: "16px",
                borderRadius: "32px",
                textTransform: "uppercase",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
                cursor: "pointer",
              }}
              {...buttonName}
            />
            <Box tag={"div"} css={{ marginTop: "12px" }}>
              <Image
                alt={"Image description for better SEO"}
                src={"/images/logo.png"}
                dynamic={true}
                css={{ width: "100%", maxWidth: "180px" }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        tag={"div"}
        css={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button
          text={"Modifier"}
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          size={"small"}
          variant={"contained"}
          color={"secondary"}
          iconStart={false}
          iconEnd={false}
          css={{
            paddingLeft: "16px",
            paddingRight: "16px",
            paddingTop: "6px",
            paddingBottom: "6px",
          }}
          {...edit}
        />
        <Button
          text={"Envoyer"}
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          size={"small"}
          variant={"contained"}
          iconStart={false}
          iconEnd={false}
          css={{
            paddingLeft: "16px",
            paddingRight: "16px",
            paddingTop: "6px",
            paddingBottom: "6px",
          }}
          {...validate}
        />
      </Box>
    </Container>
  );
};

export default EmailResume;
// #endregion
