/** Build with repleek */
// #region Import
import React from "react";

import {
  Avatar,
  AvatarProps,
  Box,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Form,
  FormProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";
// #endregion

// #region Type Definition
type ReportingViewHeaderProps = Partial<ContainerProps> & {
  action: ButtonProps & {},
  form: Partial<FormProps>,
  logo: Partial<AvatarProps>,
  name: TypographyProps & {},
  email: TypographyProps & {},
};
// #endregion

// #region ReportingViewHeaderComponent
const ReportingViewHeader: React.FC<ReportingViewHeaderProps> = (props) => {
  const { action, form, logo, name, email, ...rootProps } = props;

  return (
    <Container
      maxWidth={"lg"}
      disableGutters={false}
      fixed={false}
      css={{
        padding: "36px",
        backgroundColor: "rgba(255,255,255, 1)",
        borderStyle: "none",
        boxShadow:
          "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        borderRadius: "16px",
        marginBottom: "16px",
        ".mui-dark &": {
          backgroundColor: "rgba(39, 37, 45, 1)",
          boxShadow:
            "rgba(255, 255, 255, 0.02) 0px 1px 3px 0px  , rgba(248, 252, 255, 0.15) 0px 0px 0px 1px ",
        },
        "@media (max-width: 700px)": { padding: "14px" },
      }}
      {...rootProps}
    >
      <Form
        mode={"onSubmit"}
        css={{
          gap: "12px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
        {...form}
      >
        <Box
          tag={"div"}
          css={{
            flexGrow: "1",
            gap: "12px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Avatar
            variant={"rounded"}
            component={"div"}
            css={{
              width: "84px",
              height: "84px",
              "@media (max-width: 700px)": { width: "64px", height: "64px" },
            }}
            {...logo}
          />
          <Box
            tag={"div"}
            css={{
              flexGrow: "1",
              paddingLeft: "26px",
              "@media (max-width: 700px)": { paddingLeft: "8px" },
            }}
          >
            <Typography
              variant={"h4"}
              text={"Mon titre ici"}
              gutterBottom={false}
              noWrap={false}
              css={{ "@media (max-width: 700px)": { fontSize: "22px" } }}
              {...name}
            />
            <Typography
              variant={"body1"}
              text={"Mon titre ici"}
              gutterBottom={false}
              noWrap={false}
              {...email}
            />
          </Box>
        </Box>
        <Box
          tag={"div"}
          css={{ gap: "14px", display: "flex", alignItems: "center" }}
        >
          <Button
            text={"Modifier"}
            type={"submit"}
            disabled={false}
            disableElevation={false}
            disableFocusRipple={false}
            disableRipple={false}
            fullWidth={false}
            variant={"contained"}
            iconStart={false}
            iconEnd={false}
            css={{
              paddingLeft: "16px",
              paddingRight: "16px",
              paddingTop: "6px",
              paddingBottom: "6px",
            }}
            {...action}
          />
        </Box>
      </Form>
    </Container>
  );
};

export default ReportingViewHeader;
// #endregion
