/** Build with repleek */
// #region Import
import React from "react";

import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Icon,
  Image,
  ImageProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { AiOutlineLogin, AiOutlineLogout } from "react-icons/ai";
// #endregion

// #region Type Definition
type SidebarProps = Partial<BoxProps> & {
  logoLigth: Partial<ImageProps>,
  user: Partial<BoxProps>,
  avatar: Partial<AvatarProps>,
  name: TypographyProps & {},
  menu: Partial<BoxProps>,
  logout: ButtonProps & {},
  login: ButtonProps & {},
  logoDark: Partial<ImageProps>,
};
// #endregion

// #region SidebarComponent
const Sidebar: React.FC<SidebarProps> = (props) => {
  const {
    logoLigth,
    user,
    avatar,
    name,
    menu,
    logout,
    login,
    logoDark,
    ...rootProps
  } = props;

  return (
    <Box
      tag={"div"}
      css={{
        width: "280px",
        height: "100%",
        boxShadow:
          "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px , rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
        overflowY: "hidden",
        borderRadius: "12px",
      }}
      {...rootProps}
    >
      <Box
        tag={"div"}
        css={{
          height: "100%",
          backgroundColor: "rgba(255,255,255, 1)",
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingTop: "24px",
          paddingBottom: "16px",
          borderRadius: "12px",
          display: "flex",
          flexDirection: "column",
          ".mui-dark &": {
            backgroundColor: "rgba(39, 37, 45, 1)",
            boxShadow:
              "rgba(255, 255, 255, 0.02) 0px 1px 3px 0px  , rgba(251, 253, 255, 0.15) 0px 0px 0px 1px ",
          },
        }}
      >
        <Image
          alt={"Image description for better SEO"}
          src={"/images/logo-dark.svg"}
          href={"/"}
          dynamic={true}
          css={{ width: "100%" }}
          {...logoLigth}
        />
        <Image
          alt={"Image description for better SEO"}
          src={"/images/logo-light.svg"}
          href={"/"}
          dynamic={true}
          css={{ width: "100%" }}
          {...logoDark}
        />
        <Box
          tag={"div"}
          css={{
            textAlign: "center",
            paddingTop: "32px",
            paddingBottom: "32px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          {...user}
        >
          <Avatar
            variant={"circular"}
            initial={"AA"}
            component={"div"}
            css={{
              width: "84px",
              height: "84px",
              fontFamily: "Unbounded",
              fontSize: "28px",
              color: "rgba(255,255,255, 1)",
              borderWidth: "3px",
              borderStyle: "solid",
              borderColor: "rgb(239, 239, 250)",
              marginBottom: "8px",
              background:
                "linear-gradient(90deg, rgb(151, 150, 240) 0%, rgb(251, 199, 212) 100%)",
            }}
            {...avatar}
          />
          <Typography
            variant={"h3"}
            text={"Mon titre ici"}
            gutterBottom={false}
            noWrap={false}
            css={{ fontSize: "20px" }}
            {...name}
          />
        </Box>
        <Box tag={"div"} css={{ flexGrow: "1" }} {...menu} />
        <Button
          text={"Se déconnecter "}
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          size={"small"}
          variant={"contained"}
          color={"secondary"}
          iconStart={true}
          iconEnd={false}
          css={{
            fontSize: "14px",
            paddingLeft: "14px",
            paddingRight: "8px",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
          IconStart={
            <Icon
              color={"inherit"}
              fontSize={"inherit"}
              icon={AiOutlineLogout}
            />
          }
          {...logout}
        />
        <Button
          text={"Se connecter "}
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          size={"small"}
          variant={"contained"}
          color={"primary"}
          iconStart={true}
          iconEnd={false}
          href={"/login"}
          css={{
            fontSize: "14px",
            paddingLeft: "14px",
            paddingRight: "8px",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
          IconStart={
            <Icon
              color={"inherit"}
              fontSize={"inherit"}
              icon={AiOutlineLogin}
            />
          }
          {...login}
        />
      </Box>
    </Box>
  );
};

export default Sidebar;
// #endregion
