import React, { useEffect, useMemo, useRef, useState } from "react";
import { Accountnotion } from "@/modules/strapi-sdk/lib/interfaces";
import { useThrottle } from "@uidotdev/usehooks";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";
import { notionUnlinkedAccounts } from "@/store/notion";
import { NotionItem, NotionPageSelect } from "@/components";
import { useWatch } from "react-hook-form";

const SocialPageList: React.FC<{ onLink: (data?: Accountnotion) => void }> = (
  props
) => {
  const { onLink } = props;

  const [search, setSearch] = useState("");
  const throttledSearch = useThrottle(search, 800);
  const ref = useRef<HTMLInputElement>(null);

  const accounts = useRecoilValue(notionUnlinkedAccounts);
  const refresh = useRecoilRefresher_UNSTABLE(notionUnlinkedAccounts);

  const pagesFilterd = useMemo(
    () =>
      accounts?.filter(({ name }) =>
        name?.toLowerCase().includes(throttledSearch.toLocaleLowerCase())
      ),
    [accounts, throttledSearch]
  );

  const notionId = useWatch({
    name: "notion_id",
  });

  useEffect(() => {
    if (ref.current) {
      ref.current.value = "";
    }
  }, []);

  return (
    <NotionPageSelect
      list={{
        children: pagesFilterd?.map((page) => (
          <NotionItem
            key={page.notion_id}
            onClick={() =>
              onLink(page.notion_id !== notionId ? page : undefined)
            }
            avatar={{
              ...(page.icon?.type &&
                ["external", "file"].includes(page.icon.type) && {
                  src: page.icon?.value,
                }),
              initial: page.name?.substring(0, 2),
            }}
            name={{ text: page.name }}
            selected={{
              hidden: page.notion_id !== notionId,
            }}
          />
        )),
      }}
      search={{
        inputName: "search",
        onKeyUp: (event) => setSearch(event.currentTarget.value),
        ref,
      }}
      refresh={{ onClick: refresh }}
    />
  );
};

export default SocialPageList;
