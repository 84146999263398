import React, { Suspense } from "react";
import Layout, { PistacheLoader } from "@/components/layout";
import ReportingComponent from "@/components/features/reports/ReportingComponent";
import { useParams } from "react-router-dom";

const Reporting: React.FC = () => {
  const { id, accountId } = useParams();
  return (
    <Layout title="Pistache - repporting" mode="ADMIN">
      <Suspense fallback={<PistacheLoader />}>
        {id && accountId && (
          <ReportingComponent accountId={accountId} id={id} />
        )}
      </Suspense>
    </Layout>
  );
};

export default Reporting;
